.dms {
  .headerFlex {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    flex-direction: row;
    margin-left: -20px;
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    font-family: Roboto;
    font-style: normal;
    margin-bottom: 24px;
    margin-left: -15px;
  }
  &__card {
    max-height: 500px;
    padding: 25px 16px;
    display: flex;
    flex-direction: column;

    &__title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #2b323b;
      text-transform: capitalize;
      padding-left: 15px;
    }
    &__monthLabel {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #2b323b;
      padding-left: 15px;
    }
  }
  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: "#F7F7F7";
    margin-left: -15px;
    margin-bottom: 10px;
  }
  &__best-title {
    margin-top: 20px;
    margin-left: -15px;
    margin-bottom: 30px;
  }
}

