
.icon-and-text {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 5px;
}

.text-container {
  margin-left: 5px;
}
