.safety-academy-preview {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
}

.main {
  display: flex;
  flex-direction: column;
  margin: 24px 16px;

  &__top {
    display: flex;
    flex-direction: row;

    >img {
      min-width: 300px;
      height: 164px;
      border-radius: 10px;
      border: 2px solid #DEDEDE;
    }

    &__right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      justify-content: space-between;

      >div {
        display: flex;
        flex-direction: column;
      }

      &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #808080;
        margin-bottom: 4px;
      }

      &__data {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #B5B5B5;
        margin-bottom: 24px;
      }

      &__subtitle {
        font-weight: normal;
        font-size: 17px;
        line-height: 26px;
        color: rgba(67, 67, 67, 0.6);
        margin-bottom: 8px;
      }

      &__buttons-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        padding: unset;
        margin: unset;

        &__see-all {
          border: none;
          background: none;
          text-decoration: underline;
          color: blue;
        }
      }
    }
  }

  &__bottom {
    margin-top: 24px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
  }
}