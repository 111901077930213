.expandable-table {
  width: 100%;
  background-color: #ffffff;
  // height: 60vh;
  // overflow-y: scroll;

  .table {
    width: 100%;
    border: 0px;
    // height: 60vh;
    thead {
      background-color: white;
      z-index: 10;
      // transform: translateY(-2px);

      // th {
      //   position: sticky;
      //   top: 0;
      //   background-color: white;
      //   border-top: 1px solid #F0F0F0;
      //   border-bottom: 1px solid #F0F0F0;
      //   box-shadow: 0px 10px 10px 2px rgba(0, 0, 0, 0.05);
      //   font-size: 18px;
      // }
    }
    .row {
      height: 30px;
      display: revert;
      margin: 0;
      width: 100%;

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }

      .cell {
        &:nth-child(1) {
          display: list-item;
        }
      }
    }
    .row_depth1 {
      font-size: 11px !important;
    }
    .row_depth2 {
      font-size: 10px !important;
      height: 25px !important;
    }
    .cell_depth1,
    .cell_depth2 {
      padding-left: 15px !important;
    }
    .cell_depth0 {
      padding: none !important;
    }

    .expanded-row {
      background-color: #e4f2da !important;
    }
    .expanded-row-items {
      text-align: left;
      font-weight: bold;
    }

    .expandable-row {
      &:hover {
        cursor: pointer;
      }
    }

    .row-subItems {
      background-color: #ffffff;
    }

    .row-subHeader {
      flex: 1;
      background-color: #f0f0f0;
      position: relative;
      border-bottom: 2px solid #00000010;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
    .row-subHeader-title {
      font-weight: 800;
      font-size: 14px;
      color: #808080;
    }
  }
}
