.global_search{
  margin-right: 16px;
  .input_with_icon{
    position: relative;
    .icon_search{
      position: absolute;
      width: 12px;
      left: 10px;
      top: 10px;
      z-index: 2;
    }
    .icon_close{
      position: absolute;
      width: 16px;
      right: 12px;
      top: 8px;
      z-index: 999;
      cursor: pointer;
      &.in-active{
        display: none;
      }
    }
  
    .global-search-input{
      padding: 0px 12px 0px 30px;
      transition: width 0.2s;
      border-radius: 5px;
      border: 2px solid rgb(223, 225, 230);
      background-color: rgb(255, 255, 255);
      color: rgb(107, 119, 140);
      box-sizing: border-box;
      height: 32px;
      font-size: 16px;
      line-height: 8px;
      &.active{
        box-shadow: 0 0 1px 0 rgba(0, 124, 172, 0.82);
        border-color: rgba(189, 195, 199,0.5);
        width: 300px;
      }
      &.in-active{
        box-shadow: none;
        border-color: #ECEFF4;
        width: 200px;
      }
      &:focus{
        outline: none;
      }
      &::placeholder{
        color: rgba(107, 119, 140, 0.5);
        font-weight: 400;
      }
    }
  }
  &__result_pad{
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px, rgba(9, 30, 66, 0.3) 0px 0px 1px;
    background-color: rgb(255, 255, 255);
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    position: absolute;
    width: 300px;
    z-index: 999;
    border-radius: 3px;
    height: 50vh;
    max-height: 400px;
    padding: 10px 0px;
    overflow: scroll;
    .section_heading{
      padding: 0px 10px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 8px 0px;
      color: rgb(107, 119, 140);
    }
    .empty_result{
      display: flex;
      justify-content: center;
      p{
        font-size: 16px;
        color: rgba(52, 73, 94,1.0);
      }
    }
  }
  &__result_card{
    width: 100%;
    cursor: pointer;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    &:hover{
      background-color: rgba(236, 240, 241,1.0);
    }
    .result_title{
      background-color: transparent;
      color: rgb(9, 30, 66);
      text-decoration: none;
      font-size: 14px;
      padding: 5px 0px;
    }
  }
}