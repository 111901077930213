.list-with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 398px;
  box-sizing: border-box;

  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    width: 100%;
    text-align: left;
    padding-bottom: 12px;
  }

  &__list {
    list-style-type: none;
    margin: unset;
    padding: unset;
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}