.confirm-truck-details {
  margin-top: 20%;
  width: 100%;
  height: 100%;

  .left-margins {
    margin-left: 0.55rem;
  }

  .sap-number-container {
    margin-left: 0.35rem;
    max-width: 95%;

    .sap-number {
      font-weight: bold;
      font-size: 2rem;
      color: #0D2A59;
      max-width: 100%;
    }
  }
}
