.overview-locations-top-bottom {
  .table {
    height: 231px;
    overflow: scroll;
    font-size: 12px;
    margin: 10px 0px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;

    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }

  .tab-item {
    margin-right: 15px !important;
  }

  .label-default {
    margin-left: 15px;
    padding-bottom: 10px;
  }

  .table-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 12px;
    margin-top: -30px;


    p {
      max-width: 100px;
      color: #808080;
      text-align: right;
    }
  }
}