.privacy-policy {
  p {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}