$fixed-footer-height: 9.5rem;
$custom-var: 3rem;

.anonymous-complaint {
  position: relative;
  /*necessary for sticky footer*/
  min-height: 100%;
  /*necessary for sticky footer*/
  max-width: 100%;
  /*necessary for sticky footer*/
  width: 100%;
  padding: 0 0 $fixed-footer-height 0;
  /*necessary for sticky footer*/
  margin: 0;
  /*necessary for sticky footer*/
  background-color: white;
  overflow-x: hidden;
  /*necessary for sticky footer*/
  overflow-y: scroll;
  /*necessary for sticky footer*/

  .step-page {
    min-height: 100%;
    width: 100%;
    margin: auto !important;
    padding: 0.5rem 1.5rem;
  }

  .nav-footer {
    height: $fixed-footer-height;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .justify-start {
    justify-content: flex-start !important;
  }
}