@import "../../../../styles/constants.scss";

  .remember-password-text {
    color: $strong-blue;
    font-size: 15px;

    a {
      margin-left: 10px;
    }
  }

  .centered-container.marginBottomToLast{
    margin-bottom: 24px; 
  }