@import "../../styles/color-codes.scss";

.header_area {
  padding: 3vh 1vw 0.5vh;
  .close-btn {
    // position: relative;
    // left: 90%;
  
    &:hover {
      cursor: pointer;
    }
  }
}

.intro_section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.info_section {
  display: flex;

  .id_section {
    margin-right: 1rem;
  }
}

.covid_text {
  background-color: $BACKGROUND;
  padding-left: 1vw;
  padding: 1vh 1vw;
  border: 1px solid $DEFAULT;

  h5 {
    font-weight: 400;
    color: $DANGER;
    margin: 0;
  }
}

.tests {
  .test_btns_container {
    padding: 2vh 1vw 0;
  }
  .test {
    border-top: 1.5px solid $DEFAULT;
    padding: 1vw 2vh;
  }
}