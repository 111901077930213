$GRAY_100: #dae0eb;

.main-navigation-header {
  background-color: white;
  border-bottom: 1px solid $GRAY_100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  .dropdown-content {
    transform: translateX(-36%);
  }

  .dropdown-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @extend .padding-header-navigation;
  }

  .logout-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logout-icon {
    width: 15px;
  }

  .logout-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .setting-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      padding-left: 10px;
    }
  }
  .reposition-popup .dropdown-content {
    right: -50px;
  }

  .logo-image {
    width: 20px;
    margin-right: 10px;
  }

  .content-container {
    @extend .padding-header-navigation;
  }

  h3 {
    margin-bottom: 0;
    display: inline-block;
    font-size: 16px;

    @media (min-width: 576px) {
      font-size: 20px;
    }
  }

  p {
    display: inline-block;
  }

  svg {
    margin-top: -3px;
    height: 13px;
  }

  .title {
    font-weight: bolder;
    font-size: 30px;
  }

  .sub-title {
    font-weight: bolder;
    font-size: 18px;
  }

  .stripped-style {
    all: unset;
  }

  .logout:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  .left-content {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }

  .back-icon {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .back-container {
    padding-right: 10px;
  }

  .back-container-empty {
    @extend .back-container;
    height: 20px;
    width: 214.14px;
  }

  .link-dropdown {
    padding: unset;
  }
}

.padding-header-navigation {
  padding: 15px 15px 7px 15px;
}
