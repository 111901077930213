.pyramid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__top-row {
    margin-bottom: 35px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  svg {
    max-height: 310px;
  }
}