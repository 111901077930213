.location-areas-config {
  .option-row {
    padding: 2px 10px;
    margin: 2px 10px 2px -15px;
  }

  .input-row {
    margin-left: -20px;
  }

  .btn {

    // margin-right: 10px;
    border-radius: 5px;

    &:hover {
      background-color: rgb(232, 232, 232);
    }
  }
  .selected-btn {
    background-color: rgb(239, 239, 239);
    font-weight: bold;
  }

  .section-amount {
    font-size: small;
  }

  .remove-option {
    font-size: small;
    padding-top: 3px;

     &:hover {
      text-decoration: underline;
     }
  }

  .delete-action-container {
    .basic-button, .outline-button {
      margin-top: 20px;
    }
    .basic-button {
      width: 100%;
    }
  }
}
