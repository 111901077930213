@import "styles/constants";

$elementWidth: 274px;
$borderRadius: 5px;
$textPadding: 0.6em 1em;

@mixin check {
  position: absolute;
  right: 7px;
  top: 7px;
  height: 33px;
}

@mixin dropdown {
  width: $elementWidth;
  position: relative;

  /* borders */
  .on-click-dropdown {
    border-width: 2px;
    border-style: solid;
    border-radius: $borderRadius;

    &.valid-location {
      width: 100%;
      border-color: $valid_input_border;
      background-color: white;
    }

    &.invalid-location {
      width: 100%;
      border-color: $invalid_input_border;
      background-color: $untouched_input_bg;
    }
  }

  .full-content {
    width: $elementWidth;
  }

  .select {
    max-width: 100%;
    padding: $textPadding;
    padding-right: 25px;
    height: 50px;
    font-weight: normal;
    border: 0;
    // see through so it doesn't hide the colors provided by valid-locations and
    // invalid-locations above [they're passed to dropdown className]
    background-color: transparent;
  }

  img {
    @include check;
  }
}

.anonymous-form-dropdown {
  @include dropdown;

  .dropdown-constraints {
    max-height: 150px;
    overflow: scroll;
  }
}
