.overview-participation-table {
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
  }

  .header-title {
    display: flex;
    flex-direction: row;
  }

  .overview-header {
    margin-left: 8px;
  }

  .table {
    margin-top: 5px;
    height: 236px;
    overflow: auto;
    margin: 10px 0px;
    font-size: 12px;
  }

  .table-description {
    color: #808080;
    position: relative;
    bottom: -10px;
    margin-right: 10px;
  }

  .table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;

    .value-container {
      display: flex;
      flex-direction: row;
    }

    .percent {
      margin-right: 10px;
    }

    &:nth-child(even) {
      background-color: #FAFAFA;
    }

  }

  .label-default {
    font-size: 16px;
  }
}
