.sif-complaince {
  .no-chart-margin {
    margin-bottom: 50px;
  }
  .complaince_radialBar_card {
    min-height: 200;
    width: 275px;
    background-color: #f2f2f2;
  
    .data {
      span {
        float: right;
      }
    }
  }
  .drop-downs-rowed {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
}
