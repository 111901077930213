.errorMessage {
  display: flex;
  .error-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 231px;
    width: 100%;
    background-color: white;
    flex-direction: column;
    .error-img {
      height: 108px;
      width: 180px;
      margin-bottom: 32px;
    }
    .message-title {
      margin-bottom: 10px;
    }
    .reload-page {
      border: 0;
      background-color: transparent;
      color: #CAA038;
      font-weight: 600;
      font-size: 14px;
      &:focus {
        outline: none;
      }
    }
  }
}
