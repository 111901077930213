.anonymous-truck-scann {
  .main-container {
    text-align: center;
    margin-top: 40px;
  }

  .truck-number-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    p { margin-left: 8px; }
  }

  .details-text {
    margin-top: 50px;
    color: #727171;
    margin-bottom: 20px;
  }

  .action-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding: 15px 30px;
    box-shadow: 0 2px 5px 0 rgba(230,230,230,0.5);
    margin-bottom: 12px;
    p { margin-left: 20px; font-weight: bold; }
    svg { width: 40px; }
    img {  width: 40px; }
  }

  .centered-container {
    text-align: center;
    margin-top: 40px;
  }
}
