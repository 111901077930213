@import './color-codes.scss';
// Please use file src/utils/scss-colors/color-codes.scss for new color codes

// Layout
$content-separation: 30px;
$card-padding: 15px;
$margin-unit: 16px;
// Colors
$light-accent: #F4F8FF;
$light-grey: #DBE1EB;
$muted-grey: #CBCFD3;
$default-grey: #878787;
$hover-grey: #f0f0f0;
$error-highlight: #D47420;
$full-color-background: linear-gradient(90deg, #F16E55 0%, #C00000 100%);
$primary-blue: #1876F6;
$secondary-blue: #5B9AF9;
$strong-blue: #0D326E;
// v1
$primary: #C00000;
$secondary: #836464;
$tertiary: #45120F;
$warning: #e27111;
$danger: #F15382;
$success: #70AD47;
$dark: black;
$light: #AFB8C5;
$white: white;
$primaryLight: #F7FAFF;
$successLight: #FC9898;
$tertiaryLight: #FFF7F7;
$successDark: #C87C30;
$muted: #98A5B4;
$placeholder-color: #8A99AA;
$invalid_input_border: #E0EBFE;
$valid_input_border: #ECF3FE;
$untouched_input_bg: #FAFCFF;
