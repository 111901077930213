.pressed-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  height: 40px;
  border-radius: 8px;
  flex: none;
  margin-right: 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #f0f0f0;

  svg {
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }

  &:hover {
    background: #f7f7f7;
  }

  &--selected {
    background: rgba(214, 244, 222, 0.5);
    border: 1px solid #56cd9d;
    box-sizing: border-box;
    color: #56cd9d;

    svg {
      stroke: #56cd9d;

      rect,
      line,
      path,
      circle {
        stroke: #56cd9d;
      }
    }
  }
}
