.DPOSummaryCard {
  background-color: #E4F2DA;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border-radius: 10px;

  .evaluation-texts {
    display: flex;
    flex-direction: row;
    // margin-right: 5px;
    div {
      margin-right: 10px;
      margin-top: 10px;
    }

    .description {
      font-weight: bold;
    }

    p {
      font-size: 10px;
    }

    .number {
      font-size: 20px;
      font-weight: bolder;
    }
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .flex-1 {
    flex: 1;
  }

  .card-container {
    display: flex;
    flex-grow: 1;
    border-radius: 5px;
    padding: 10px
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1
  }

  .badge-icon-container {
    justify-content: flex-end;
    display: flex;
  }

  .badge-icon {
    width: 30px;
    height: 30px;
  }

  .badge-icon-title {
    font-size: 7px;
  }

  .sub-header {
    font-size: 15px;
  }

  .title-percentage {
    color: #70AD47;
    font-size: 22px;
    font-weight: bold;
  }

  .footer-text {
    font-size: 10px;
    justify-content: space-evenly;
    display: flex;
  }


  .switch {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 0 1px 0 rgba(128,128,128,0.5);
    background-color: #F0F0F0;
    border-radius: 3px;
    padding: 3px;
    height: 23px;

    p {
      font-weight: bold;
    }
    .switch-item {
      padding: 1px 10px;
      border-radius: 2px;
      cursor: pointer;
    }

    .selected {
      background-color: white;
      box-shadow: 0 1px 1px 0 rgba(128,128,128,0.5);

    }
  }
}
