
.page-container{
  height: 100%;
  background-color: white;
}
.wo__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin-left: 16px;
  padding: 24px 24px 24px 0px;
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
}

.main-title {
  color: #000;
  /* Headers/ H2 */
  font-family: Quicksand;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}
.cancel-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--FONT_COLORS-GRAY_4, #DEDEDE);
}

.eliminar-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--ERROR, #E92B36);
}

.tutorial-class {
  border-radius: 4px;
  border: 1px solid  #D6D6D6;
  background:#FFF;
  color: black;
  color: var(--FONT_COLORS-GRAY_10, #404040);
  text-align: center;

  /* Button/Pill BUTTON text */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  margin-left: 16px;
}

.description-base {
  color: var(--FONT_COLORS-GRAY_9, #808080);
  /* Body/BODY-XS */
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  margin-top: 24px;
  border-radius: 4px;
  background: var(--FONT_COLORS-GRAY_1, #F7F7F7);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 16px;
}

.wo__cards-row{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.wo__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.filter__flex-row{
  border-radius: 4px;
  background: var(--FONT_COLORS-GRAY_2, #F2F2F2);
  display: flex;
  padding: 4px 12px;
  gap: 5px;
  align-items: center;
  justify-content: space-around;
}
.wo__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.wo__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  font-family: Jost;
  line-height: 24px;
  color: blue;
}

.wo__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: blue;
}

.wo__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.wo__scroll-view{
  overflow-y: scroll;
  height: 65vh;
}
.wo__scroll-view::-webkit-scrollbar{
  display: none;
}
.shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.wo_dashboard-option-menu {
  div {
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // background-color: red;
    margin-left: 6px;
    margin-right: 24px;
    cursor: pointer;
    img {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
  }
}
.wo_dashboard-menu-item {
  &:hover {
    background-color: gray;
  }
}
.wo__menu_editTitle{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: #2B323B;
}
.wo__menu_deleteTitle{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: #E92B36
}
.wo__title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.wo__answered-question-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040;
}
.wo__total-question-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040;
}
.wo__answered-question-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.wo__checklist-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #6C63FF
}
.wo__id{
  font-weight: 500;
  font-size: 17px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.wo_dashboard-menu-item-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wo__deleteMoal-title {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  color: #2B323B;
}
.wo__deleteMoal-description {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  color: #2B323B;
}
.wo__deleteMoal-button_container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.wo__deleteMoal-cancel_button{
  color: #404040;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  border-color: #D6D6D6;
  background-color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
}
.wo__deleteMoal-delete_button{
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #E92B36;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.wo__delete-loader{
  margin-top: 45px;
}
.wo__tabs {
  margin-bottom: 24px;
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.wo__tab{
  .tab-item{
    width: 76px;
  }
  .wo__not-selected-tab{
    border-bottom: 0px;
    color: #878787
  }
}
.wo__question-wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
.searchDropdown {
  margin-left: auto;
  margin-right: 5%;
  margin-top: 20%;
  outline: none;
  background-color: #FFFFFF;
  padding-top: 16px;
  height: 428px;
  width: 304px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}
.searchBar {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  margin-left: 16px;
  margin-right: 16px;
  gap: 8px;
  padding-bottom: 12px;
  border: 1px solid #DEDEDE;
  border-radius: 8px,
}
.subLine {
  font-size: 12px;
  font-weight: 500;
  margin-left: 16px;
  margin-right:16px;
}
.search_content {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.search_content:hover {
  background-color: #F2F2F2;
}
.instruction-wrapper {
  margin: 0 auto 0 0;
  background-color: #F7F7F7;
}
.checklist_section {
  min-height: 600px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      margin-right: 30px;
    }

    .search-bar {
      width: 600px;
      max-width: 600px;
      margin-left: auto;
    }
  }

  &__body {

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  
    .hidden{
      display: none
    }
  

    .checklist_section_instructions {
      display: flex;
      justify-content: space-between;

      .checklist_section_button_filled {
        color: #fff;
        border-radius: 5px;
        background-color: #e92b36;
        margin-left: 15px;
        font-weight: 400;
      }
    }

    .selected-tab {
      border-bottom: 2px solid;
      border-bottom-color: #CAA038;
      color: #CAA038;
    }

    .tabs-container {
      margin-left: 0px;
    }

    .chart_header {
      display: flex;
      justify-content: space-between;
      position: relative;

      .chart_filters {
        display: 'flex';
        flex-direction: 'column';
      }

      .drop-downs-rowed{
        display: flex;
        justify-content: end;
        margin-top: 10px;
      }

      .react-datepicker {
        position: absolute;
        right: 0px;
      }
    }
    .chart_body{
      display: flex;
      align-items: center;
      min-height: 500px;
    }

    .table {
      margin-top: 30px;
      min-height: 300px;
      .rt-resizable-header-content{
          font-weight: 400;
          font-size: 16px;
      }

      .ReactTable {
        .rt-td {
          font-weight: 400 !important;
          font-size: 16px;
          text-align: left;
          padding-left: 10px
        }
      }
    }

    .chart {
      min-height: 300px;
      text-align: center;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ol {
      margin-top: 30px;
    }

    .checklist_section_button {
      border: 1px solid #dedede;
      border-radius: 5px;
      color: #808080;
      background-color: #fff;
      font-weight: 400;
    }

    .checklist_section_button_filled {
      color: #fff;
      border-radius: 5px;
      background-color: #f7b64c;
      margin-left: 10px;
      font-weight: 400;
    }
    .checklist_section_download_button_filled {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 400;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid #DEDEDE;
    }
    .checklist_section_update_template_button_filled {
      color: #fff;
      border-radius: 5px;
      background-color: #e92b36;
      margin-left: 10px;
      font-weight: 400;
    }
  }
}

.fivesTable .rt-noData {
  margin-top: -18px
}

.fivesTable .pagination-bottom {
  margin-top: 200px
}
