.overview-wrapper {
  max-width: calc(100% - 30px);
  margin: 0 auto 0 0;
  ;
}

.overview {
  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    padding: unset;
    margin-bottom: 16px;
    margin-left: -15px;
  }
}

.row {
  justify-content: space-between;
}

.col-3 {
  max-width: calc(25% - 10px);
}

.col-6 {
  max-width: calc(50% - 10px);
}

.col-5 {
  max-width: calc(41.66667% - 10px);
}

.col-7 {
  max-width: calc(58.33333% - 10px);
}

.col-9 {
  max-width: calc(75% - 10px);
}

.overview-card {
  min-height: 372px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  >div {
    width: 100%;
  }
}

.overview-card--small {
  min-height: 356px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.makeStyles-nested-10 {
  padding-left: 15px !important;
}