.extended-view {
  background-color: white;
  padding-top: 5px;
  margin: auto;
  width: 760px;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: #404040;
      text-transform: capitalize;
      margin-bottom: 12px;
      width: 100%;
    }

    &__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
      margin-bottom: 24px;
      width: 100%;
    }
  }

  &__banner {
    margin: auto;
    border-radius: 8px;
    margin-bottom: 24px;
    width: 100%;
    height: auto;
  }

  &__info {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
    width: 100%;

    >div {
      display: flex;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 12px;
      border: 1px solid #DEDEDE;
    }

    &__author {
      display: flex;
      flex-direction: column;

      span {
        &:first-of-type {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #404040;
          margin-bottom: 4px;
        }

        &:last-of-type {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #878787;
        }
      }
    }

    &__right {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #DEDEDE;
        box-sizing: border-box;
        border-radius: 4px;

        img {
          height: 24px;
          width: 24px;
          margin-right: unset;
        }
      }
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #404040;
    margin-bottom: 40px;
  }

  &__attached-files {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;

    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #808080;
      margin-bottom: 16px;
    }

    &__files-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}