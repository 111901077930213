@import "../../../../styles/color-codes.scss";

// incident-details = id
.id-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #FAFAFA;
}

.id-main-container {
  align-items: 'flex-start';
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--fontcolorsgray-3, #f0f0f0);
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--backgroundstertiarybacklight, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
}

.id-assign-container {
  display: flex;
  margin-top: 15px;
}

.id-assigned-isAssigned-container {
  display: flex;
  align-self: center;
  margin-top: 10px;
}

.id-assigned-isAssigned-icon {
  margin-right: 10px;
  width: 18px;
}

.id-assigned-isAssignedText {
  color: #4472C4;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.id-assign-picker {
  width: 100%;
}

.id-description {
  color: var(--TEXT, #2b323b);
  max-width: 261px;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Jost, sans-serif;
}

.id-type-of-issue {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  gap: 0px;
}

.id-type-of-issue-title {
  color: var(--TEXT, #2b323b);
  align-self: start;
  text-wrap: nowrap;
  font: 700 24px/100% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
}

.id-issue-number {
  color: var(--fontcolorsgray-8, #878787);
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
  font: 500 12px/133% Jost, sans-serif;
}

.id-location-row {
  align-items: flex-start;
  align-self: start;
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  // width: 302px;
  // max-width: 100%;
  gap: 8px;
}

.id-location-image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.id-location-label {
  color: var(--fontcolorsgray-8, #878787);
  align-self: stretch;
  text-wrap: nowrap;
  font: 600 14px/171% Plus Jakarta Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.id-location-container {
  align-items: flex-start;
  border-radius: 6px;
  background-color: var(--fontcolorsgray-1, #f7f7f7);
  align-self: stretch;
  display: flex;
  max-width: 100%;
  gap: 4px;
  padding: 6px 12px;
}

.id-location-picker {
  border-radius: 6px;
  background-color: var(--fontcolorsgray-1, #f7f7f7);
  width: 160px;
}

.id-issue-description {
  color: var(--fontcolorsgray-10, #404040);
  align-self: stretch;
  margin-top: 12px;
  width: 100%;
  font: 400 16px/150% Jost, sans-serif;
}

.id-row-info {
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 0px;
}

.id-row-info-label {
  color: var(--fontcolorsgray-8, #878787);
  align-self: stretch;
  max-width: 634px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Jost, sans-serif;
}

.id-row-info-value {
  color: var(--PRIMARY_DARK, #2b323b);
  align-self: stretch;
  text-align: end;
  font: 500 17px/141% Jost, sans-serif;
  max-width: 60%;
}

.id-row-info-picker {
  width: 60%;
}

.id-row-divider {
  border: 1px solid #DEDEDE;
}

.id-button-solid {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--SECONDARY, #4472C4);
  color: #fff;
  margin: 0 4px;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  text-align: center;
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.id-button-text {
  text-align: center;
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 0 6px;
}

.id-button-outline {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ffffff;
  color: #404040;
  border: 1px solid #D6D6D6;
  margin: 0 4px;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.id-button-image {
  width: 24px;
  height: 24px;
}

.id-button-image-gap {
  margin: 0px 6px;
}

.id-buttons-container {
  display: flex;
}

.id-photo-empty {
  display: flex;
  padding: 48px 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: #F7F7F7;
  margin-bottom: 20px;

  color: #2B323B;
  text-align: right;

  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.id-photo {
  aspect-ratio: 1.9;
  object-fit: contain;
  object-position: center;
  width: 200px;
  overflow: hidden;
  align-self: end;
  max-width: 100%;
  margin: 40px 0 24px;
}

.return-sio-modal {
  width: 800px; 
  left: 26%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 30px 54px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $WHITE;
  z-index: 101;
  box-shadow: 0 6px 10px 0 rgba(207,207,207,0.5);
  border-radius: 12px;
  overflow: auto;
}

.return-sio-section-container {
  display: flex;
  justify-content: center;
}

.return-sio-question-title {
  color: var(--TEXT, #2b323b);
  align-self: start;
  text-wrap: nowrap;
  font: 700 24px/100% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
  padding-top: 32px;
}

.return-sio-description-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.return-cause-input {
  height: 72px;
}

.return-sio-buttons-container {
  margin-top: 20px; 
}

.id-return-isReturned-icon-check {
  width: 24px;
}

.clicked {
  background: #DEDEDE !important;
}
