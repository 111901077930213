.incidents-over-time,
.pareto-sif {
  &__top-row {
    display: flex;

    .tab-item {
      padding-top: 5px;
    }

    .tab-title {
      font-size: 18px;
    }

    span {
      margin-left: 16px;
    }
  }
}

.lti-vs-sif {
  &__top-row {
    display: flex;
    justify-content: space-between;

    span {
      flex: 1;
      font-family: Roboto;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      padding-left: 55px;
    }
  }

  &__bottom-row {
    width: 100%;
    display: flex;

    >div {
      width: 50%;
    }
  }
}

.screen-header {
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
}
