.search-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);

  &__top {
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 24px;

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #878787;
    }
  }

  &__results-list {
    display: flex;
    flex-wrap: wrap;
  }
}