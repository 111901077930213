@import '../../styles/_fonts';

.dropdown-component {
  width: 201px;
  position: relative;

  .dropDownButton {
    box-sizing: border-box;
    height: 39px;
    width: 100%;
    border: 1px solid #DAE2EB;
    border-radius: 2px;
    background-color: #FFFFFF;
    cursor: pointer;
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      color: #000000;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: -0.26px;
      line-height: 18px;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .show-dropdown {
    width: auto;
    display: flex;
    border: unset;
    background-color: unset;
    position: absolute;
    top: 35px;
    right: -200px;
    height: 350px;
  }

  .calendarContainer {
    width: fit-content;
    height: auto;
    background-color: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 0 20px 35px 0 rgba(201, 201, 201, 0.5);
    display: flex;
    flex-direction: row;
    padding-left: unset !important;
    padding-right: unset !important;
    position: relative;
    overflow: hidden;
    z-index: 100;
    left: -200px;

    .secondCalendarContainer {
      width: fit-content;
      height: fit-content;
    }


    .headerDivider {
      position: absolute;
      width: 2px;
      height: 40px;
      background-color: white;
      top: 5px;
      left: 230px;
    }

    .header {
      background-color: #8EB7FF;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 10px 20px;
      box-sizing: border-box;

      .stringContainer {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
      }

      .stringHeader {
        height: 18px;
        width: 120px;
        color: #FFFFFF;
        text-align: left;
        font-size: 13px;
        font-weight: 900;
        line-height: 18px;
        margin: unset;

        &.small {
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
        }
      }

      .headerIcons {
        width: 55px;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 0 10px;
      }
    }
  }

  .react-datepicker__header {
    padding-top: unset;
    border-bottom: unset;
  }

  .react-datepicker__day-names {
    height: 51px;
    padding-top: 21px;
    background-color: white;
    box-sizing: border-box;
  }

  .react-datepicker__day-name {
    height: 15px;
    color: #000000;
    font-size: 13px;
    font-weight: 500;
  }

  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
    border-bottom-color: #8EB7FF !important;
  }

  .react-datepicker__day {
    margin: unset;
    border-radius: unset;
    width: 2rem;
    margin: 6px 0;
    color: #000000;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
  }

  .react-datepicker__day--selected {
    background-color: #2574FB !important;
    border-radius: 50% !important;
    color: white !important;
    font-weight: bold;
  }

  .react-datepicker__day--selected:hover {
    background-color: #2574FB !important;
    border-radius: 50% !important;
    color: white;
    font-weight: bold;
  }

  .react-datepicker__day--in-range {
    background-color: #E6F2FF;
    color: black;
  }

  .react-datepicker__day--in-range:hover {
    background-color: #E6F2FF;
  }

  .startDate {
    background-color: #2574FB !important;
    border-radius: 50% !important;
    color: white;
    font-weight: 900;
  }

  .startDate:hover {
    background-color: #2574FB !important;
    border-radius: 50% !important;
    color: white;
    font-weight: 900;
  }

  .disableDay {
    color: #CEDAED;
  }

  .formsContainer {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
  }

  .formsContainer .numberOfDaysInfo {
    font-weight: 100;
    font-size: 11px;
    margin-top: 5px;
  }

  .formsContainer .applyButton {
    margin-top: 16px;
    background-color: #2574FB;
    border-radius: 3px;
    color: white;
    border-color: transparent;
    font-size: 13px;
    position: absolute;
    bottom: 12px;
    width: 88%;
    font-weight: 400;
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .formsContainer .formString {
    margin-top: 10px;
    height: 12px;
    width: 110px;
    color: #636363;
    font-size: 9px;
    font-weight: 200;
    line-height: 12px;
  }

  .formsContainer .formStyles {
    height: 26px;
    width: 148px;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-size: 13px;
    padding: 1px 5px;
  }

  .formsContainer .active {
    border: #8EB7FF solid 3px;
    border-image: linear-gradient(to right, #62D4EC, #64AEF6) 10;
    //-webkit-border-image: -webkit-gradient(linear, left bottom, right top, from(#62D4EC), to(#64AEF6)) 21 30 30 21 repeat repeat;
    box-shadow: 1px 1px 5px 2px rgba(184, 184, 184, 0.41);
    font-weight: bold;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: unset;
  }

  .boxOptions {
    width: 185px;
    height: 100%;
    background-color: white;
    border-left: 1px solid #EFEFEF;
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    right: 200px;
    z-index: 100;
    :hover {
      cursor: pointer;
    }
  }

  .boxOption {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;
    box-sizing: border-box;
    color: #000000;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    &.select {
      border-top: 1px dashed #DAE0EB;
    }

    &.selected {
      background-color: #E6F2FF;
    }
  }

  .react-datepicker__day--keyboard-selected:active {
    background-color: unset;
  }
}
