@import "../../../styles/application";

.userTypeContainer {
  margin-bottom: 30px;
}
.userType {
  margin-right: 30px;
  font-size: 13px;
  color: $PRIMARY;
  cursor: pointer;
  opacity: 0.5;
}
.selectedUserType {
  color: $PRIMARY;
  font-weight: bold;
  opacity: 1;
}
.userType:first-child{
  margin-left: 0;
}
.userType:last-child{
  margin-right: 0;
}
.usersTableContainer {
  border: 2px solid $BORDERS;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  max-height: calc(100vh - 364px);
  overflow-y: auto;
}

.userTableHeading {
  font-size: 13px;
  border-bottom: 1px solid $BORDERS;
  background: none;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 30px 15px 15px;
  align-items: center;
}

.usersTableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 35px 15px 15px;
  font-size: 12px;
  align-items: center;
}

.userName {
  margin-right: 10px;
  @extend .defaultFontSize;
}

.positionColumn {
  flex: 2;
  margin-right: 10px;
  @extend .defaultFontSize;
  @extend .text-alignC
}

.slugColumn {
  flex: 3;
  max-width: 200px;
  @extend .defaultFontSize;
  @extend .text-alignC
}

.emailColumn {
  flex: 4;
  @extend .defaultFontSize;
  @extend .text-alignC
}

.flexPositionColumn {
  flex: 2;
  max-width: 200px;
  text-align: left;
  @extend .defaultFontSize;
}

.defaultFontSize{
  font-size: 16px;
}

.flexIdColumn{
    flex: 3;
    @extend .text-alignC
}

.flexEmailColumn{
    flex: 4;
    @extend .text-alignC
}

.text-alignC{
    text-align: center;
}

.usersTableRow:nth-of-type(even) {
  background: none;
}
.usersTableRow:nth-of-type(odd){
  background: $BACKGROUND;
}

.usersTableRow:first-child {
  padding-top: 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.buttonsContainer > div {
  flex: 1;
  margin-right: 20px;
}
