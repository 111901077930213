@import "../../styles/constants.scss";

$marginDefault: 15px;
$alignItems: 'center';

.textLabel {
    display: 'flex';
    align-items: $alignItems;
    margin-left: '10px';
}

.contentWrapper {
    display: 'flex';
    flex-direction: 'row';
    align-items: $alignItems;
    margin-bottom: '5px';
}

.descriptionTextWrapper {
    display: flex;
    flex-wrap: wrap;
}

.descriptionText {
    margin: $marginDefault;
    color: $default-grey;
    font-weight: 100;
};

.seeMoreText {
    color: $primary-blue;
    cursor: pointer;
    margin: $marginDefault;
    font-weight: 300;
}
