$elementWidth: 274px;
.date-time-container {
  width: $elementWidth;

  .DayPicker {
    background-color: #fff;
    border: 1px solid rgba(211, 211, 211, 0.55);
    box-shadow: 0 3px 5px 3px rgba(74, 74, 74, 0.15);
    border-radius: 2px;
    outline-width: 0 !important;

    * {
      outline-width: 0 !important;
    }
  }

  .DayPicker-Caption {
    position: relative;
    z-index: 100;
    max-width: 170px;
    font-family: "Nunito Sans", sans-serif;
  }

  .day-picker-nav-bar {
    background-color: #F4F4F4;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}
