.button-with-icon-and-text {
  display: 'flex';
  justify-content: space-between;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 1em;
  outline: none;
  padding: 13px 23px;
  background-color: #2574FB;
}

.icon {
  width: 30px;
}
