$GRAY_10: #878787;
$GRAY_2: #f2f2f2;

.add_more_images_container {
  background-color: $GRAY_2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .add_more_image {
    width: 15px;
    height: 15px;
  }
  .add_image_text {
    font-size: 14px;
    color: $GRAY_10;
  }
}
.delete_image {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -5px;
}
