.ifd-container {
  // height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #F2F2F2;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

}

.ifd-container-selected {
  background-color: #ECF3FF;
}

.ifd-container-disabled {
  background-color: #F2F2F2;
  opacity: 0.5;
  cursor: not-allowed;
}

.ifd-item-label {
  color: #404040;
  text-align: center;
  font-family: 'Quicksand';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  min-width: 80px;
  gap: 15px;
}

.ifd-item-label-selected {
  color: #4472C4;
}