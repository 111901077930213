// incidents-list-card = ilc
.ilc-container {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 2px 1px 0px rgba(99, 99, 99, 0.25);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 10px;

  &.selected {
    border: 2px solid #4472c4
  }
}

.ilc-header {
  align-items: flex-start;
  align-self: start;
  display: flex;
  margin-top: 16px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  border: 0;
  height: fit-content;
}


.ilc-title {
  color: var(--PRIMARY_DARK, #2b323b);
  align-self: start;
  white-space: nowrap;
  font: 500 17px/141% Jost, sans-serif;
}

@media (max-width: 991px) {
  .ilc-title {
    white-space: initial;
  }
}

.ilc-subtitle {
  color: var(--fontcolorsgray-8, #878787);
  align-self: start;
  white-space: nowrap;
  font: 500 12px Jost, sans-serif;
}

@media (max-width: 991px) {
  .ilc-subtitle {
    white-space: initial;
  }
}

.ilc-timestamp {
  color: var(--fontcolorsgray-8, #878787);
  align-self: start;
  white-space: nowrap;
  font: 500 12px/133% Jost, sans-serif;
}

@media (max-width: 991px) {
  .ilc-timestamp {
    white-space: initial;
  }
}

.ilc-content {
  align-items: flex-start;
  align-self: start;
  display: flex;
  margin-top: 16px;
  width: 302px;
  max-width: 100%;
  gap: 8px;
}

.ilc-image-wrapper {
  align-items: flex-start;
  border-radius: 6px;
  background-color: var(--fontcolorsgray-1, #f7f7f7);
  align-self: stretch;
  display: flex;
  // max-width: 100%;
  gap: 4px;
  padding: 6px 12px;
}

.ilc-image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  // max-width: 100%;

}

.ilc-image-label {
  color: var(--fontcolorsgray-8, #878787);
  align-self: stretch;
  white-space: nowrap;
  font: 600 14px/171% Plus Jakarta Sans, -apple-system, Roboto,
    Helvetica, sans-serif;
}

.ilc-description {
  color: var(--fontcolorsgray-8, #878787);
  margin: 4px 0 16px;
  font: 400 16px/150% Jost, sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2rem;
}