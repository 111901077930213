.tabs-container {
  margin-left: 0px;
}
.selected-tab {
  border-bottom: 2px solid;
  border-bottom-color: #CAA038;
  color: #CAA038;
}

.upload_container {
  display: flex;
  // justify-content: space-between;
}

.upload_button {
  position: relative;
  margin-right: 10px;
  
  .upload_button_filled {
    color: #fff;
    border-radius: 4px;
    background-color: #EFBE3F;
    padding: 8px 12px;
    font-weight: 400;
    margin: 0 5px;
  }
}

.downloadMoal {
  &__header {
    margin-bottom: 300px;
  }
  &__progress_bar {
    margin-top: 32px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }
  } 
  &__downloading_title {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
    margin-bottom: 32px;
  }
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: #2B323B;
  }
  &__button_container {
    display: flex;
    margin-top: 64px;

    &__cancel_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
    &__yearFilter {
      position: absolute;
      display: flex;
      top: 180px;
      flex-direction: row;
  
      &__yearTitle {
        font-family: 'Jost', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: #878787;
        padding-left: 16px;
      }
  
      &__monthTitle {
        font-family: 'Jost', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: #878787;
        padding-left: 18px;
      }
    }
    &__download_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }

  &__continue_button_container {
    display: flex;
    margin-top: 64px;
    justify-content: flex-end;

    &__continue_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 20px;
  }
  &__download_button {
    color: #404040;
    border-radius: 4px;
    background-color: #fff;
    font-weight: 700;
    font-size: 12px;
    font-family: 'Quicksand', sans-serif;
    border: 1px solid #DEDEDE;
    margin-right: 20px;
  }
  &__download_button_filled {
    color: #fff;
    border-radius: 4px;
    background-color: #EFBE3F;
    font-weight: 700;
    font-size: 12px;
    font-family: 'Quicksand', sans-serif;
    margin-right: 20px;
  }
}