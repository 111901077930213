.distribution-center-incidents-index {
  .table-filters {
    // width: 20%;
    span {
      padding-left: 10px;
      padding-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: #9DAECC;

    :hover {
      cursor: pointer;
    }
  }

  .description-square {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
  }

  .close-button {
    color: #2574FB;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
}

.search-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 12px 12px 16px;
  height: 48px;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #878787;
}

.search {
  width: 550px;
  margin-left: 30px;
  margin-right: 30px;
}

.noRecords {
  display: flex;
  justify-content: center;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #2B323B;
}