@import "../../../styles/color-codes";

.userNameAndIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: bolder;
}

.userInfoIconContainer > img {
  padding: 10px;
  background-color: $BACKGROUND;
  margin-right: 10px;
}
.userInfoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.upperForm {
  width: 80%;
  margin-top: -40px;
}

.userIcon > img {
  padding: 10px;
  border: 8px solid $BORDERS;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.user-info {
  &__input--error {
    font-size: 14px;
    line-height: 14px;
    color: $PRIMARY;
    margin-left: 85px;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__options {
      text-transform: capitalize;
    }

    &__permission {
      display: flex;
      flex-direction: column;
      flex: 1;

      &__list {
        flex: 1;
        max-height: 195px;
        overflow-y: auto;
      }
    }
  }
}

.user-info-modal {
  .user-info-wrapper {
    display: flex;
    justify-content: flex-end;
    z-index: 9999;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
  }
  .routineList {
    overflow-y: auto;
    margin-top: 10px;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }
    & > .routineContainer {
      margin-bottom: 56px;
      &:last-child {
        margin-bottom: 10px;
      }
    }
    & > .routineContainer > div > img {
      width: 24px;
      height: 24px;
    }
  }
}

.remove-all {
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
  float: right;
  padding-right: 18px;
  padding-top: 4px;
  button {
    display: "inline-block";
    float: "right";
    color: $PRIMARY;
    background-color: transparent;
    border-width: 0;
    font-size: 14px;
    font-weight: 400;
    &:focus {
      outline: 0;
    }
  }
}

.select_all_container {
  position: relative;
  width: "100%";
  justify-content: flex-start !important;
  margin: 0 -20px 0 -20px !important;
  .select_all {
    position: sticky;
    top: 0;
    padding-bottom: 40px;
    margin-right: 40px;
    padding-left: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    & > button {
      border-width: 0;
      border-radius: 8px;
      padding: 10px 20px;
      margin: 10px 0 0 0;
      font-size: 14px;
      font-weight: 700;
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: #dedede;
        color: $PRIMARY_DARK;
      }
    }
    .filter_button {
      display: flex;
      text-transform: "capitalize";
      border-radius: 8px;
      padding: 10px 20px;
      margin: 10px 0 0 0;
      font-size: 14px;
      font-weight: 700;
    }
    .pillar-active {
      color: $WHITE;
      background-color: $PRIMARY;
      width: 150px;
    }
    .pillar-inactive {
      color: $BLACK;
      border-color: $BUTTON_TEXT;
      background-color: $BUTTON_BACKGROUND;
      width: 150px;
    }
    .single-filter-button-active {
      color: $PRIMARY_DARK;
      background-color: $PRIMARY_LIGHT;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .filter-button-active {
      color: $WHITE;
      border-color: transparent;
      background-color: $PRIMARY;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .filter-button-inactive {
      color: $BLACK;
      border-color: $BUTTON_TEXT;
      background-color: $BUTTON_BACKGROUND;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.user-info__bottom_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.user-info__bottom {
  overflow-y: auto;
  overflow-x: hidden;
  .headerContainer {
    justify-content: "space-between";
    flex-direction: "row";
  }
}

.permissionsWrapper {
  margin: 0 0 24px;
  font-weight: bold;
  text-transform: capitalize;
}

.permissionsWrapper > div > img {
  width: 24px;
  height: 24px;
}
