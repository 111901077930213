@import "../../styles/constants";

.safety-academy-home {
  font-family: Roboto;

  &__top {
    display: flex;
    flex-direction: column;
    padding: unset !important;
    margin-left: -15px !important;

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 8px;

      span {
        color: $PRIMARY;
      }
    }

    &__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
      margin-bottom: 24px;
    }
  }
}