.pill-with-icon {
  font-family: Roboto;
  display: flex;
  background: none;
  border: none;
  align-items: center;
  padding: 4px 12px;
  width: 154px;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 28px;
  margin: 8px 8px 0 0;

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
  }

  &__icon {
    max-height: 24px;
    max-width: 24px;
  }

  &__text {
    max-width: 82px;
    max-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 24px;
    padding-left: 4px;
    text-align: center;
  }

  &__extension {
    font-size: 14px;
    line-height: 24px;
  }
}