.editDailyAgenda {
  margin: 0 24px;

  &__addActivityModal {
    &__label {
      font-family: Outfit;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #2B323B;
      margin-bottom: 32px;
    }

    &__name {
      margin-bottom: 16px;

      input {
        width: 100%;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        background-color: transparent;
        padding: 10px;
        font-size: 1em;
        outline: none;
        margin: 5px 0;
        
        &:hover {
          border: 2px solid #4472C4;
        }
      }

      img {
        top: 11px !important
      }
    }

    &__description {
      margin-bottom: 16px;

      input {
        width: 100%;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        background-color: transparent;
        padding: 10px;
        font-size: 1em;
        outline: none;
        margin: 5px 0;

        &:hover {
          border: 2px solid #4472C4;
        }
      }

      img {
        top: 13px !important
      }
    }

    &__pillarContainer {
      margin-bottom: 16px;
    }

    &__footerDescription {
      font-family: 'Jost';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #B5B5B5;
    }
  
    &__buttonContainer {
      display: flex;
      margin-top: 32px;
      justify-content: flex-end;
  
      &__cancelButton {
        color: #404040;
        border-radius: 4px;
        background-color: #FFFFFF;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid #DEDEDE;
  
        &:hover {
          background-color: #F2F2F2;
        }
        &:active {
          background-color: #D6D6D6;
        }
      }
      &__createButton {
        color: #FFFFFF;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 12px;
  
        &:hover {
          background-color: #C7DCFFE9;
        }
        &:active {
          background-color: #4472C4;
        }
      }
      &__createButtonWithoutHover {
        color: #FFFFFF;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 12px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &__info {
      display: flex;
      align-items: center;

      &__goBack {
        padding: 12px 16px;
        border: 1px solid #D6D6D6;
        border-radius: 8px;
        cursor: pointer;
      }
      &__content {
        margin-left: 16px;

        &__title {
          font-family: 'Outfit';
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #000;
        }
        &__subtitle {
          font-family: 'Jost';
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #404040;
          margin-top: 8px;
        }
      }
    }
    &__edit_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #DEDEDE;
      margin-left: 8px;
    }
  }

  &__dnd {
    display: flex;
    flex-direction: row;

    &__dndCard1 {
      display: flex;
      flex-direction: row;
      height: calc(100vh - 190px);
      justify-content: space-between;
      width: 100%;
  
      &__cards {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 97%;
        padding: 6px 12px;
        background-color: #FAFAFA;
        overflow-y: scroll;
        border-radius: 8px;
        margin-bottom: 12px;
  
        &::-webkit-scrollbar {
          display: none;
        }

        &__searchWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__search {
            input {
              width: 100%;
              border: 0px solid rgb(236, 239, 244);
              border-radius: 5px;
              background-color: transparent;
              padding: 10px;
              font-size: 1em;
              outline: none;
              margin: 5px 0;
            }
          }
          &__newTaskLabel {
            font-family: 'Jost';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #4472C4;
            cursor: pointer;
          }
        }
  
        &__wrap {
          height: calc(100% - 60px);
  
          &__cardContainer {
            height: inherit;
  
            &__card {
              width: 100%;
              border: 2px solid rgb(236, 239, 244);
              border-radius: 5px;
              background-color: #FFFFFF;
              padding: 8px 10px;
              font-size: 1em;
              outline: none;
              margin-bottom: 8px;
              display: flex;
              flex-direction: row;
              justify-content: space-between; 
  
              &__title {
                color: #404040;
                white-space: nowrap;
                overflow-x: scroll;
                scrollbar-width: none;
                font-family: 'Jost';
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
              }
            }
          }
          &__noDataWrap {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            max-height: calc(82vh - 230px);
            min-height: calc(82vh - 230px);
            overflow: hidden;
            padding-top: 48px;
            overflow-y: scroll;
            scroll-behavior: smooth;
            transition: all 500ms;
  
            &::-webkit-scrollbar{ 
              display: none; 
            }
  
            &__title {
              font-family: 'Jost';
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #43434399;
            }
            &__subTitle {
              font-family: 'Jost';
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              text-align: center;
              color: #B5B5B5;
            }
          }
        }
      }
    }
    &__dndCard2 {
      display: flex;
      flex-direction: row;
      height: calc(100vh - 190px);
      justify-content: space-between;
      width: 100%;
      overflow-x: scroll;
  
      &__positionCardsWrap {
        width: 100%;

        &__addPositionWrap {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          &__container {
            &__label {
              font-family: 'Jost';
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #4472C4;
            }
            &__disabledLabel {
              font-family: 'Jost';
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #4472C4;
              opacity: 0.5;
              cursor: default;
            }
          }

          &__buLabel {
            margin-left: 24px;
            font-family: 'Jost';
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #404040;
          }
        }
  
        &__cards {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          padding: 6px 12px;
          background-color: #FAFAFA;
          overflow-y: scroll;
          margin-bottom: 12px;
          border-radius: 8px;
    
          &::-webkit-scrollbar {
            display: none;
          }
  
          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            &__label {
              font-family: 'Jost';
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #878787;
            }
  
            &__deletePostionWrap {
              display: flex;
              align-items: center;
              cursor: pointer;
  
              &__label {
                font-family: 'Jost';
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                color: #878787;
              }
            }
          }
    
          &__search {
            input {
              width: 100%;
              border: 0px solid rgb(236, 239, 244);
              border-radius: 5px;
              background-color: transparent;
              padding: 10px;
              font-size: 1em;
              outline: none;
              margin: 5px 0;
            }
          }
    
          &__wrap {
            height: calc(100% - 60px);
    
            &__cardContainer {
              height: inherit;
    
              &__card {
                width: 100%;
                border: 2px solid rgb(236, 239, 244);
                border-radius: 5px;
                background-color: #FFFFFF;
                padding: 8px 10px;
                font-size: 1em;
                outline: none;
                margin: 8px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between; 
                align-items: center;
    
                &__title {
                  color: #404040;
                  white-space: nowrap;
                  overflow-x: scroll;
                  scrollbar-width: none;
                  font-family: 'Jost';
                  font-size: 17px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: left;
                }

                &__positionWrap {
                  display: flex;
                  align-items: center;

                  &__deleteIcon {
                    margin-left: 5px;
                    margin-bottom: 3px;
                  }
                }
              }
            }
            &__noDataWrap {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 24px 56px;
              overflow: hidden;
              overflow-y: scroll;
              scroll-behavior: smooth;
              transition: all 500ms;
    
              &::-webkit-scrollbar{ 
                display: none; 
              }
    
              &__title {
                font-family: 'Jost';
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #43434399;
              }
            }
          }
        }
        
        &__noPositionsAddedWrap {
          padding-top: 110px;
          padding-bottom: 48px;
          padding-left: 88px;
          padding-right: 88px;
  
          &__label {
            font-family: 'Jost';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #43434399;
          }
        }
      }
    }
  }
}

