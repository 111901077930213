.cashless {
  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    padding: unset;
    margin-bottom: 16px;
    margin-left: -15px;
  }
}

.empty-state {
  height: 100%;
}

.dropdown-content {
  max-height: 330px;
  overflow-y: auto;
}
.contenWrap{
  margin-top: 20px;
}
