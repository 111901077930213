.total-incidents-chart {
  .tabs-container {
    display: flex;
    justify-content: space-between;
  }

  .hc-details {
    margin: 5px;
    margin-top: 18px;
    margin-right: 27px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .hc-icon {
    margin: 0 5px;
    margin-top: 5px;
  }

  .hc-header {
    color: #404040;
    font-size: 12px;
  }

  .hc-value {
    font-size: 13px;
    font-weight: bold;
  }

  .label-default {
    margin-left: 15px;
    position: relative;
  }

  .dropdown-label-o {
    position: absolute;
    bottom: 10px;
  }

  .dropdown-loading-labels {
    padding-bottom: 10px;
  }

  .apexcharts-legend-series {
      display: flex !important;
      flex-direction: row-reverse!important;
      margin: 0 !important;

      .apexcharts-legend-marker {
          margin-left: 4px !important;
      }

      .apexcharts-legend-text {
          color: #878787 !important;
      }
  }

  .apexcharts-yaxis-label {
      tspan {
          color: white !important;
      } 
  }

}
  
