.margin-left-25 {
  margin-left: 25px;
}

.analytics_radialBar_card {
  min-height: 200;
  width: 275px;

  .data {
     span {
       float: right;
     }
  }
}
