.incidents-pie-chart {
 
  .legendCard {
    padding: 8px;
    width: 130px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .legendCard:hover {
    padding: 8px;
    width: 130px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #F7F7F7;
  }

  .rowNew{
    display:flex;
    flex-direction:row;
    align-items:center;
  }

  .dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .circle1 {
    align-self: center;
    width: 14px;
    height: 14px;
    border-radius: 20%;
    background-color: #70AD47;
    margin-right: 8px;
  }

  .circle2 {
    align-self: center;
    width: 14px;
    height: 14px;
    border-radius: 20%;
    background-color: #E4F2DA;
    margin-right: 8px;
  }

  .legend-name {
    color: #00000090
  } 

  .label-default {
    padding-left: 13px;
    padding-bottom: 5px;
  }

}
