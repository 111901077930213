.dailyAgenda {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    &__title {
      font-family: "Outfit";
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }
    &__button_container {
      display: flex;
  
      &__schedule_button {
        color: #FFFFFF;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid #DEDEDE;
        margin-left: 8px;
      }
      &__downloadIcon {
        margin-right: 8px;
      }
      &__download_button {
        color: #404040;
        border-radius: 4px;
        background-color: #fff;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;
  
        &:hover {
          background-color: #F2F2F2;
        }
        &:active {
          background-color: #D6D6D6;
        }
      }
    }
  }
  &__userInfo {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__profile {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__userImage {
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        &__name {
          font-family: 'Jost';
          font-size: 17px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        &__email {
          font-family: 'Jost';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
      }
    }
    &__progressWrapper {
      display: flex;

      &__progress {
        background-color: #F7F7F7;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        margin-left: 12px;
  
        &__info {
          margin-right: 20px;
          margin-left: 8px;
          &__title {
            font-family: 'Jost';
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #2B323B;
          }
          &__subtitle {
            font-family: 'Jost';
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #808080;
          }
          &__subtitleWrap {
            display: flex;

            &__subtitle {
              font-family: 'Jost';
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              text-align: left;
              color: #808080;
            }

            &__date {
              font-family: 'Jost';
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              text-align: left;
              color: #808080;
              margin-left: 4px;
            }
            &__capitalize {
              text-transform: capitalize;
            }
          }
        }
  
        &__indicator {
          position: relative;
          display: inline-flex;
  
          &__label {
            position: absolute;
            font-family: 'Jost';
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  &__table {
    width: 100%;
    border: 0px;
    margin-top: 16px;
    margin-bottom: 16px;

    thead {
      background-color: white;
      z-index: 10;
      th {
        border: 0px;
        color: #B5B5B5;
        text-align: left;
        font-weight: 500;
        font-family: 'Jost';
        padding: 12px;
        font-size: 12px;
      }
    }
    tbody td {
      padding: 12px;
      border: none;
      font-weight: 500;
      font-family: 'Jost';
      font-size: 12px;
      color: #404040;
    }
  
    // tbody tr {
    //   border-bottom: 1px solid #f0f0f0;
    // }
    .row {
      height: 30px;
      display: revert;
      margin: 0;
      width: 100%;

      // &:nth-child(odd) {
      //   background-color: #f7f7f7;
      // }

      .cell {
        &:nth-child(1) {
          display: list-item;
        }
      }
    }
    .row_depth1 {
      font-size: 11px !important;
    }
    .row_depth2 {
      font-size: 10px !important;
      height: 25px !important;
    }

    .cell_depth0 {
      padding: none !important;
    }

    .expanded-row {
      background-color: #F7FAFF !important;
    }
    .row_depth3.expanded-row {
      background-color: #DCE9FF !important;
    }
    .row_depth4 {
      background-color: #FAFAFA !important;
    }
    .row_depth4.expanded-row {
      background-color: #DCE9FF !important;
    }
    .row_depth5 {
      background-color: #FAFAFA !important;
    }
    .expanded-row-items {
      text-align: left;
      // font-weight: bold;
      // color: #4472C4;
    }

    .expandable-row {
      &:hover {
        cursor: pointer;
      }
    }

    .row-subItems {
      background-color: #ffffff;
    }

    .row-subHeader {
      flex: 1;
      background-color: #f0f0f0;
      position: relative;
      border-bottom: 2px solid #00000010;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
    .row-subHeader-title {
      font-weight: 800;
      font-size: 14px;
      color: #808080;
    }
  }
  &__monthSelector {
    margin-top: 16px;
  }
  &__disclaimer {
    margin: 16px 0px;

    &__title {
      font-family: 'Jost';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #808080;
    }
  }
}

.downloadMoal {
  &__progress_bar {
    margin-top: 32px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }
  } 
  &__downloading_title {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
    margin-bottom: 32px;
  }
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: #2B323B;
  }

  &__continue_button_container {
    display: flex;
    margin-top: 64px;
    justify-content: flex-end;

    &__continue_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }

  &__yearFilter {
    position: absolute;
    display: flex;
    top: 180px;
    flex-direction: row;

    &__yearTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 16px;
    }

    &__monthTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 18px;
    }
  }
  &__button_container {
    display: flex;

    &__cancel_button_wrap {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #DEDEDE;

      &:hover {
        background-color: #F2F2F2;
      }
      &:active {
        background-color: #D6D6D6;
      }
    }
    &__download_button_wrap {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 16px;

      &:hover {
        background-color: #C7DCFFE9;
      }
      &:active {
        background-color: #4472C4;
      }
    }
    &__download_button_disabled_wrap {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 16px;
    }
  }
}

.expanded-row-title {
  color: #4472C4 !important;
}

.expanded-row-title.cell_depth3 {
  color: #404040 !important;
}

.expanded-row-title.cell_depth4 {
  color: #404040 !important;
}

.row-title.cell_depth5 {
  color: #878787 !important;
}

.expanded-row-title.cell_depth5 {
  color: #404040 !important;
}

.row-title.cell_depth6 {
  color: #878787 !important;
}
