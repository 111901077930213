@import "../../../styles/color-codes";
.verify-user-header {
  margin: 0 10px 40px 10px;
}
.verifyButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emailInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emailInput > input {
  flex: 1;
}
.emailInput > span {
  font-size: 13px;
  margin: 0 10px 0 0;
}

.error-and-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.errorText {
  font-size: 13px;
  color: $PRIMARY;
  font-weight: bold;
}
