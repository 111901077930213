@import "styles/constants";

$tertiaryLightOpaque: rgba(152, 174, 252, 0.31);
$tertiaryLightTinted: rgba(96, 126, 240, 0.51);
$elementWidth: 274px;
$borderRadius: 5px;
$textPadding: 0.6em 1em;

@keyframes fade-up {
  from {
    opacity: 0.2;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin fade-up-animation {
  animation: fade-up 0.6s ease-out;
}

@mixin check {
  position: absolute;
  right: 7px;
  top: 7px;
  height: 33px;
}

@mixin input-style {
  &::placeholder {
    color: $muted;
  }

  &[value=''] {
    border-color: $invalid_input_border;
    background-color: $untouched_input_bg;
  }

  &:not([value='']) {
    border-color: $valid_input_border;
    background-color: white;
  }

  &:focus {
    border-color: $primary;
    background-color: white;
  }
}

@mixin text-area {
  width: $elementWidth;

  textarea {
    max-width: 100%;
    height: 100px;
    padding: $textPadding;

    &::placeholder {
      color: $placeholder-color;
    }
  }
}

@mixin dropdown {
  width: $elementWidth;
  position: relative;

  /* borders */
  .on-click-dropdown {
    border-width: 2px;
    border-style: solid;
    border-radius: $borderRadius;

    &.valid-location {
      width: 100%;
      border-color: $valid_input_border;
      background-color: white;
    }

    &.invalid-location {
      width: 100%;
      border-color: $invalid_input_border;
      background-color: $untouched_input_bg;
    }
  }

  .full-content {
    width: $elementWidth;
  }

  .select {
    max-width: 100%;
    padding: $textPadding;
    padding-right: 25px;
    height: 50px;
    font-weight: normal;
    border: 0;
    // see through so it doesn't hide the colors provided by valid-locations and
    // invalid-locations above [they're passed to dropdown className]
    background-color: transparent;
  }

  img {
    @include check;
  }
}

.form-details {
  @include fade-up-animation;

  .submitter-container {
    width: $elementWidth;

    .submitter-input {
      outline: none;
      max-width: 100%;
      border: 2px solid $tertiaryLightOpaque;
      border-radius: $borderRadius;
      background-color: #fff;
      padding: $textPadding;
      height: 50px;

      @include input-style;
    }
  }

  .toggle {
    width: 100%;

    .toggle-button {
      width: 100% !important;
      border-radius: $borderRadius;
      border-width: 2px;

      &:not(:last-of-type) {
        margin-right: 2%;
      }
    }
  }

  .user-search-input-container {
    position: relative;
    width: 50%;

    img {
      @include check;
    }

    .react-autosuggest__container {
      margin-top: 0.25rem;
      width: 100%;
    }

    .react-autosuggest__suggestions-container--open {
      border-bottom: 1px solid rgba(126, 126, 126, 0.1);
      border-radius: 4px;
      box-shadow: 0 1px 12px 3px rgba(237, 237, 237, 0.7);
      position: absolute;
      top: 35px;
      z-index: 1000;
      background-color: #fff;
      max-height: 200px;
      overflow: scroll;
      width: 100%;
    }

    .react-autosuggest__suggestions-list {
      padding: 0;
      margin: 0;
    }

    .react-autosuggest__suggestion {
      list-style: none;
      padding: 0.5em 1em 0;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid rgba(128, 128, 128, 0.1);

      &:last-of-type {
        border-bottom: none;
        margin-bottom: -2px;
      }
    }

    .react-autosuggest__input {
      width: 100%;
      padding: $textPadding;
      padding-right: 45px;
      outline: none;
      border-bottom: 2px solid $tertiaryLightOpaque;
      background-color: white;

      &::placeholder {
        color: $muted;
      }

      &[value=''] {
        // border-color: $invalid_input_border;
        // background-color: $untouched_input_bg;
      }

      &:not([value='']) {
        border-color: $valid_input_border;
        background-color: white;
      }

      &:focus {
        border-color: $primary;
        background-color: white;
      }
    }
  }

  .fade-up {
    @include fade-up-animation;
  }

  .location-container {
    margin-top: 38px;

    .location-description {
      @include text-area;
      width: 50%;
    }
  }

  .risk-type-container {
    margin-top: 2.4rem;

    .other-risk-container {
      width: $elementWidth;

      .other-risk {
        height: 100px;
        padding: $textPadding;
      }
    }
  }

  .incident-description-main {
    margin-top: 42px !important;

    .incident-description-container {
      @include text-area;
      width: 50%;
    }
  }

  .risk-level-container {
    margin-top: 30px;
  }

  .is-sif-container {
    margin-top: 30px;
  }

  .sif-type-container {
    margin-top: 7.5px;
  }

  .immediate-closure-container {
    margin-top: 30px;
  }

  .corrective-action-main {
    margin-top: 34px;

    .corrective-action-container {
      margin-top: 38px;
      @include text-area;
      width: 50%;
    }
  }

  .img-button {
    cursor: pointer;

    &:hover,
    &:active {
      opacity: 0.3;
    }
  }

  .file {
    text-align: center;
    justify-content: center;

    img {
      max-height: 150px;
    }

    .uploaded-file {
      border-radius: $borderRadius;
      max-width: $elementWidth !important;
      z-index: 1000;
    }
  }

  .success-text {
    text-align: right;
    color: $success;
  }

  .check-icon-image {
    margin-left: 10px;
    width: 25px;
  }
}

.fd-sif-golden-rule-banner {
  display: flex;
  width: 480px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  background: #FAFAFA;
  margin-top: 20px;
}

.fd-sif-golden-rule-banner-2 {
  display: flex;
  width: 480px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  background: #FFEBEB;
  margin-top: 20px;
}

.fd-sif-golden-rule-banner-item-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.fd-sif-golden-rule-banner-item-left-container {
  display: flex;
  width: 100%;
  gap: 20px;
}

.fd-sif-golden-rule-banner-item-image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  overflow: hidden;
  max-width: 100%;
}

.fd-sif-golden-rule-banner-item-title {
  color: var(--PRIMARY_DARK, #2b323b);
  align-self: center;
  margin: auto 0;
  font: 400 16px/150% Jost, sans-serif;
  width: 100%;
}

.fd-sif-golden-rule-banner-item-right-value {
  color: var(--PRIMARY_DARK, #2b323b);
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 500 17px/141% Jost, sans-serif;
}

.fd-sif-golden-rule-banner-item-info {
  color: var(--fontcolorsgray-9, #808080);

  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 12px;
}