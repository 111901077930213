@import "../ActionLogCard/ActionLogCardStyle.scss";
$GRAY_9: #808080;
$GRAY_6: #b5b5b5;
$GRAY_4: #dedede;
$GRAY_10: #404040e2;
$GRAY_8: #878787;
$GRAY_2: #f2f2f2;
$GREEN_1: #56cd9d;
$GREEN_DISABLED: #6ecca6b8;
$GRAY_3: #2b323b;
$BACKGROUND_COLOR: #fafafa;
$BACKGROUND_COLOR_GRAY_1: lightgray;
$BOX_SHADOW: rgba(0, 0, 0, 0.25);
$RESPONSIBLE_BG_COLOR: #adefd1ff;
$RESPONSIBLE_TEXT_COLOR: #00203fff;
$SCROLL_BAR_BACKGROUND: #f5f5f5;
$SCROLL_BAR_SHADOW: rgba(79, 75, 75, 0.104);
$GREEN_BACKGROUND: #d6f4de;
$GREEN_TEXT: #56cd9d;

.summary_modal {
  position: relative;
  width: 80vw;
  height: 80vh;
  margin: auto;
  border-radius: 8px !important;
  transition: all 300ms;
  .modal_children_container {
    position: relative;
    height: inherit;
    width: inherit;
    background: white;
    border-radius: 8px;
    .loader {
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .edit-and-delete-button-container {
      position: absolute;
      left: 0;
      top: -50px;
      display: flex;
      flex-direction: row-reverse;
    }
    .close_btn,
    .delete_btn,
    .edit-btn {
      padding: 8px;
      border-radius: 6px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      padding: 6px 16px 6px 12px;
      border: 1px solid #d6d6d6;
      img {
        width: 18px;
        height: 18px;
        margin: 0 6px;
      }
    }
    .delete_btn {
      color: #c00000;
      margin-left: 12px;
    }
    .close_btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 6px 12px 6px 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .active_btn {
      cursor: pointer;
    }
    .disable_btn {
      color: rgba(16, 16, 16, 0.3);
    }
    .modal_children_sub_container {
      display: flex;
      height: inherit;
      border-radius: 8px;
      .summary_section {
        width: 70%;
        height: inherit;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        font-size: 0;
        .details_first_section {
          padding: 15px 20px;
          .actionlog_folionumber {
            padding: 8px 10px;
            font-weight: 600;
            line-height: 1.4;
            font-size: 18px;
            border-radius: 4px;
            letter-spacing: 0.2px;
            color: $GRAY_10;
          }
          .first_row_details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0 30px 0;
            .participant_image_section,
            .popover_container {
              display: flex;
            }
            .action_log_status_container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              margin-top: 15px;
              .time_passed {
                color: $GRAY_6;
                font-weight: 400;
                font-size: 12px;
                margin-top: 5px;
              }
            }
            .action_log_status {
              border: 1px solid $GRAY_4;
              padding: 8px;
              border-radius: 5px;
              color: $GRAY_3;
              font-weight: 400;
              font-size: 13px;
              line-height: 1;
              .pillar_image {
                width: 24px;
                height: 24px;
                margin-right: 10px;
              }
              .dropdown_image {
                width: 20px;
                margin-left: 10px;
              }
            }
          }
          .start_end_details {
            display: flex;
            width: 80%;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 16px;
            .calender_section_container {
              display: flex;
              flex-direction: column;
              margin-right: 35px;
              align-items: flex-start;
              .calender_section {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
                img {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
                p {
                  color: $GREEN_1;
                  padding: 0;
                  margin: 0;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 1;
                  margin-top: 1px;
                  text-transform: uppercase;
                }
              }
              .create_date,
              .end_date {
                color: $GRAY_9;
                font-size: 13px;
                line-height: 1;
                font-weight: 400;
                margin: 0;
                margin-left: -1px;
                margin-top: 8px;
                margin-bottom: 3px;
              }
              .time_passed {
                color: $GRAY_6;
                font-weight: 400;
                font-size: 12px;
                margin-top: 5px;
              }
            }
          }
          .start_end_status_container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          }
          .description {
            border: 1px solid $GRAY_4;
            padding: 8px 10px;
            font-weight: 600;
            line-height: 1.4;
            font-size: 18px;
            border-radius: 4px;
            letter-spacing: 0.2px;
            color: $GRAY_10;
          }
          .success_criteria {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            &__header {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              img {
                width: 25px;
                height: 25px;
                margin-right: 3px;
              }
              &__title {
                font-size: 13px;
                color: $GRAY_10;
                font-weight: 700;
                margin: 0;
                padding: 0;
                line-height: 1;
                padding-top: 3px;
                letter-spacing: 0.3px;
                text-transform: uppercase;
              }
            }
            &__description {
              border: 1px solid $GRAY_4;
              padding: 8px 10px;
              font-weight: 400;
              line-height: 1.4;
              font-size: 15px;
              border-radius: 4px;
              letter-spacing: 0.2px;
              color: $GRAY_8;
            }
          }
        }
        .details_second_section {
          display: flex;
          flex-direction: column;
          .pillar {
            padding: 16px 20px;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 15px;
          }
          .extra_details_section {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__first_section {
              width: 50%;
              display: flex;
            }
            .check,
            .created,
            .dpi {
              margin-right: 20px;
              display: flex;
              flex-direction: column;
              width: 40%;
            }
            .extra_title {
              font-weight: 400;
              font-size: 13px;
              line-height: 1;
              overflow: hidden;
              max-width: 200px;
              min-width: 200px;
              color: $GRAY_9;
              margin-bottom: 10px;
            }
            .extra_sub_title {
              font-weight: 400;
              font-size: 13px;
              line-height: 1;
              color: $GRAY_3;
            }
            .kpis {
              background: $GRAY_2;
              padding: 8px 10px;
              border-radius: 15px;
              margin-left: -1px;
              color: $GRAY_3;
            }
          }
          .evidence_sections {
            max-width: 100%;
            margin: 30px 20px;
            padding-bottom: 10px;
            display: flex;
            flex-wrap: nowrap;
            gap: 16px;
            overflow: hidden;
            overflow-x: scroll;
            scroll-behavior: smooth;
            &__image {
              position: relative;
              min-width: 156px;
              min-height: 90px;
              max-width: 156px;
              max-height: 90px;
              cursor: pointer;
              border-radius: 8px;
              margin-top: 10px;
              .responsive_img {
                width: 100%;
                border-radius: 10px;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}
.owner_image {
  position: relative;
  .crown_image {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 30px;
    right: 4px;
    border: 50%;
  }
}
.image_relative_container {
  position: relative;
  .remove_responsible_img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -1px;
    right: -1px;
    cursor: pointer;
  }
}

.select_status_form {
  min-width: 150px !important;
  .MuiOutlinedInput-input {
    padding: 5px 14px;
  }
}
.select_status {
  height: 40px !important;
  color: $GRAY_3;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  .pillar_image {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.single_status {
  color: $GRAY_3;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  .pillar_image {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.owner_image_sub_title {
  margin-top: 5px;
  text-align: center;
  color: $GRAY_9;
  font-weight: 400;
  font-size: 12px;
}
.comment_section {
  width: 30%;
  height: 100%;
  background: $BACKGROUND_COLOR;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px 12px;
  overflow: auto scroll;
}
.participant_image {
  cursor: pointer;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.remaining_participant {
  background: $RESPONSIBLE_BG_COLOR;
  color: $RESPONSIBLE_TEXT_COLOR;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_textarea {
  width: 100%;
  border: 1px solid $BACKGROUND_COLOR_GRAY_1;
  border-radius: 5px;
  font-weight: 400 !important;
}

.summary_modal_without_comment {
  width: 65vw;
}
.summary_section_full_width {
  width: 100% !important;
  padding-bottom: 50px;
}
.save_update_btn_container {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: white;
  border-radius: 7px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px -1px 2px $BOX_SHADOW;
  .btn {
    padding: 10px 75px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  .save_update {
    background-color: $GREEN_1;
    color: white;
    margin: 0 20px;
  }
  .cancel {
    background: white;
    outline: 1px solid $GRAY_10;
    color: $GRAY_10;
  }
}

.disabled_save_btn {
  cursor: not-allowed !important;
  background-color: $GREEN_DISABLED !important;
}

.no_result_found {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $GRAY_8;
}
.circular_progress {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $GRAY_8;
}
.loading_text {
  margin-left: 20px;
  color: $GRAY_8;
  font-weight: bold;
  font-size: 16px;
}
.search_bar_with_btn {
  padding: 0 20px;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  input {
    width: 270px;
  }
}
.ok_btn_secondary {
  color: $GREEN_1;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.disabled_ok_btn_secondary {
  color: $GREEN_DISABLED;
  cursor: not-allowed;
}

.note_section {
  padding: 0 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  .note_title {
    font-size: 16px;
    font-weight: bold;
    color: $GRAY_3;
  }
  .notes {
    padding: 10px;
    border: 1px solid $GRAY_4;
    border-radius: 4px;
  }
  .edit_notes {
    min-height: 30px;
    color: black;
    font-size: 14px;
    padding: 10px;
  }
  
  .greyedTxt {
    @extend .edit_notes;
    color: $GRAY_6;
  }
}

.status_time_line_text {
  font-size: 16px;
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.status_time_line_text_black {
  color: $GRAY_9;
}

.words_left {
  display: flex;
  margin-top: 5px;
  justify-content: flex-end;
  text-align: left;
  font-size: 10px;
  margin-left: 10px;
  font-weight: bold;
}

.margin_bottom {
  margin-bottom: 40px;
}

.red {
  color: red;
}

.summary_section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW !important;
  box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW !important;
  border-radius: 10px !important;
  background-color: $SCROLL_BAR_BACKGROUND !important;
}

.summary_section::-webkit-scrollbar {
  width: 9px !important;
  background-color: $SCROLL_BAR_BACKGROUND !important;
}

.summary_section::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW !important;
  box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW !important;
  background-color: lightgray !important;
}
