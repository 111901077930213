.alert {
  &-main-container {
    background-color: white;
    width: 60vw;
    position: fixed;
    left: 20vw;
    top: 20vh;
    border-radius: 12px;
    padding: 20px 35px;
    overflow: auto;
  }

  &-container {
    width: 100%;
    text-align: center;
  }

  &-image {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  &-description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
    margin-bottom: 32px;
  }

  &-title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: #2B323B;
  }

  &-buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  &-close-later {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid var(--fontcolorsgray-4, #DEDEDE);

    color: var(--fontcolorsgray-10, #404040);
    text-align: center;

    /* Button/Pill BUTTON text */
    font-family: Quicksand;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  &-close-now {
    background-color: #4472C4;
    color: #ffffff;
    text-align: center;

    /* Button/Pill BUTTON text */
    font-family: Quicksand;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 133.333% */
  }
}
