.chart-tabs {
  display: flex;
  flex-direction: row;
  margin: -15px 15px 0;
  width: 78%;
  overflow: auto;
  white-space: nowrap;

  .tab-item {
    cursor: pointer;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    border-top: 2px solid;
    border-top-color: transparent;
    color: #878787;
    // display: inline-block;
    // text-decoration: none;
  }

  .tab-item-selected {
    border-top: 2px solid;
    border-top-color: #70ad47;
    color: #404040;
    // display: inline-block;
    // text-decoration: none;
  }

  .tab-title {
    font-size: 12px;
  }

  .tab-value {
    font-size: 16px;
    font-weight: bold;
  }

  .tab-item {
    padding: 8px 24px;
  }

  .tab-item:first-child {
    margin-left: 0;
  }

  .tab-item:last-child {
    margin-right: 0;
  }
}

.chart-tabs::-webkit-scrollbar {
  display: none;
}
