.refresh-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;

    padding-left: 8.5px;
    padding-right: 8.5px;

    border: 1px solid #F0F0F0;
    border-radius: 3px;
    color: #70AD47;

    &.active{
        cursor: pointer;
        &:hover{
            background: #E4F2DA;
        } 
    }

    p {
        line-height: 8px;
        margin-left: 6px;
    }
}
