.routeDetails {
&__headerContainer{
  display: flex;
  background-color: #FFFFFF;
  padding: 16px 32px 16px 32px;

  &__wrap{
    display: flex;
    width: max-content;

    &__backArrow{
      height: 24px;
      width: 24px;
    }
    &__returnTitle{
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #CAA038;
      margin-left: 4px;
    }
  }
}
&__bodyContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  &__routeDetail{
    display: flex;
    align-items: center;
    margin-top: 32px;

    &__title{
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #000000;
    }
    &__atRiskStatusContainer{
      padding: 4px 8px 4px 8px;
      border-radius: 4px 0px 0px 0px;
      background-color: #FFF4D9;
      margin-left: 8px;
      &__status{
        font-family: 'Jost';
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #CAA038;
      }
    }
    &__statusContainer{
      padding: 4px 8px 4px 8px;
      border-radius: 4px 0px 0px 0px;
      background-color: #FFEBEB;
      margin-left: 8px;
      &__status{
        font-family: 'Jost';
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #E92B36;
      }
    }
  }
  &__tabsDetail{
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: space-between;

    &__selected-tab {
      border-bottom: 2px solid;
      border-bottom-color: #CAA038;
      color: #CAA038;
    }
    &__tabs-container {
      margin-left: 0px;
      width: unset;
    }
    &__dateDetail{
      display: flex;

      &__title{
      font-family: 'Jost';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #404040;
      }
      &__leftArrow{
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
      &__rightArrow{
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }
  }
  &__routeInfo{
    background-color: #FFFFFF;
    padding: 32px;

    &__userInfoDetails{
      display: flex;
      justify-content: space-between;
        

      &__userInfo{
        display: flex;
        align-items: center;
        width: 60%;
        overflow-wrap: anywhere;

        &__profileImage{
          height: 64px;
          width: 64px;
          border-radius: 50%;
          object-fit: cover;
        }
        &__nameRole{
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          
          &__name{
            font-family: 'Plus Jakarta Sans';
            font-size: 15px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #2B323B;
          }
          &__role{
            font-family: Jost;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #2B323B;
          }
        }
      }
      &__truckInfo{
        display: flex;
        align-items: center;
        width: 40%;
        justify-content: flex-end;
        overflow-wrap: anywhere;

        &__truckImage{
          height: 56px;
          width: 56px;
          border-radius: 50%;
        }
        &__nameRole{  
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          
          &__name{
            font-family: 'Plus Jakarta Sans';
            font-size: 15px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #2B323B;
          }
          &__role{
            font-family: Jost;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #2B323B;
          }
        }
      }
    }
    &__destinationDetails{
      margin-top: 16px;
      display: flex;
      margin-bottom: 24px;

      &__evaluator{
        padding: 12px 16px 12px 16px;
        border-radius: 8px;
        border: 1px solid #DEDEDE;
        width: 50%;
        overflow-wrap: anywhere;

        &__title{
          font-family: 'Jost';
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #808080
        }
        &__nameDetails{
          display: flex;
          margin-top: 12px;

          &__icon{
            height: 24px;
            width: 24px;
          }
          &__name{
            font-family: 'Jost';
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #2B323B;
            margin-left: 8px;
          }
        }
      }
      &__destination{
        padding: 12px 16px 12px 16px;
        border-radius: 8px;
        border: 1px solid #DEDEDE;
        width: 50%;
        overflow-wrap: anywhere;
        margin-left: 8px;

        &__title{
          font-family: 'Jost';
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #808080
        }
        &__nameDetails{
          display: flex;
          margin-top: 12px;

          &__icon{
            height: 24px;
            width: 24px;
          }
          &__name{
            font-family: 'Jost';
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #2B323B;
            margin-left: 8px;
          }
        }
      }
    }
    &__destinationInfo{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      &__nameDetails{
        display: flex;

        &__icon{
          height: 24px;
          width: 24px;
        }
        &__name{
          font-family: 'Jost';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #2B323B;
          margin-left: 8px;
        }
      }
      &__distanceInfo{
        font-family: 'Jost';
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        color: #2B323B;
      }
    }
    &__destinationInfo1{
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      &__nameDetails{
        display: flex;

        &__icon{
          height: 24px;
          width: 24px;
        }
        &__name{
          font-family: 'Jost';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #2B323B;
          margin-left: 8px;
        }
      }
      &__distanceInfo{
        font-family: 'Jost';
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        color: #2B323B;
      }
    }
    &__divider{
      border-top: 1px solid #DEDEDE;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    &__imageContainer{
      display: flex;

      &__image{
        height: 94px;
        width: 90px;
      }
      &__details{
        margin-left: 16px;

        &__name{
          font-family: 'Jost';
          font-size: 17px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        &__distance{
          font-family: 'Jost';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
      }
    }
  }
  &__mapInfo{
    background-color: #FFFFFF;
    padding: 32px;
  }
}
}
.chevron_disabled{
  opacity: 0.5;
  cursor: default;
}