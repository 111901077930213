.list-item-horizontal {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  border-bottom: 1px solid #DEDEDE;

  &:last-child {
    border-bottom: unset;
  }

  &__left {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
    }

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #808080;
      margin-bottom: 8px;
    }

    button {
      padding: unset;
      border: unset;
      background-color: transparent;
      color: #56CD9D;
      text-align: left;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__right {
    button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 4px 10px;
      position: static;
      width: 147px;
      height: 32px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: white;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      color: #404040;

      img {
        margin-right: 8px;
      }
    }
  }
}