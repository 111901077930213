.search-bar {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 65px;

  &__main-pill {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    max-width: 654px;
    height: 40px;
    background: #F7F7F7;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 32px;
    flex: 1;

    input {
      flex: 1;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      color: #878787;
    }

    >div {
      padding: 0 8px;


      &:before {
        display: flex;
        height: 18px;
        width: 1px;
        background-color: #DEDEDE;
        content: "";
        position: absolute;
        top: 21px;
      }
    }
  }

  &__file-types-picker {
    display: flex;

    &__option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      width: 95px;
      height: 40px;
      border-radius: 8px;
      flex: none;
      margin: 0px 8px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;

      svg {
        margin-right: 8px;
        height: 24px;
        width: 24px;
      }

      &:hover {
        background: #F7F7F7;
      }
    }

    &__option--selected {
      background: rgba(214, 244, 222, 0.5);
      border: 1px solid #56CD9D;
      box-sizing: border-box;
      color: #56CD9D;

      svg {
        stroke: #56CD9D;

        rect,
        line,
        path,
        circle {
          stroke: #56CD9D;
        }
      }
    }
  }

  &__search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    width: 56px;
    height: 40px;
    background: #56CD9D;
    border-radius: 32px;
    border: unset;

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: white;
      }
    }
  }
}