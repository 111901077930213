.commonFlex {
    display: flex;
    flex: 1
}

.headerFlex {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    flex-direction: row;
}
