@import "../../styles/constants.scss";

.file-preview {
  width: 140px;
  height: 105px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #E5E5E5;
  margin: 0 12px 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    visibility: hidden;
    background: $SUCCESS_LIGHT;
    padding: 4px 8px;
    border: 1px solid $SUCCESS;
    border-radius: 8px;
    color: $SUCCESS;

    img {
      margin-right: 4px;
    }
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
}