.roadSafetyDrivers {
  &__title{
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
  &__driverSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  
    &__uploadContainer{
      display: flex;
      align-items: center;
      width: max-content;

      &__uploadNewTruckFile-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        cursor: pointer;
      }
      &__lastUpdated-title{
        font-family: 'Jost';
        font-size: 15px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        text-align: left; 
        color: #878787;     
        margin-left: 16px;
        cursor: default;
      }
    }
    &__downloadContainer{
      width: max-content;
      margin-right: 16px;

      &__downloadTemplate-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        cursor: pointer;
      }
    }
  }
  &__uploadModal {
    &__progress_bar {
      margin-top: 32px;

      &__title {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #2B323B;
      }
    }

    &__downloading_title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }

    &__tileWrap{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      &__title {
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 8px;
        color: #2B323B;
      }
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      // margin-bottom: 32px;
      color: #2B323B;
    }

    &__select_container {
      margin-top: 56px;

      &__title {
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        color: #2B323B;
      }

      &__description {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: #2B323B;
      }

      &__radio_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__radio_round {
          width: 1.1em;
          height: 1.1em;
          cursor: pointer;

          &:checked {
            accent-color: #6C63FF;
          }
        }

        &__radio_title {
          font-family: 'Jost', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          // margin-left: 8px;
          color: #2B323B;
        }
      }
    }

    &__button_container {
      display: flex;
      margin-top: 104px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
        border: 1px solid #DEDEDE;
      }
    }
    &__button_retry_container {
      display: flex;
      margin-top: 64px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
        border: 1px solid #DEDEDE;
      }
    }
    &__customBullet{
      list-style: none;
      padding-left: 20px;
      margin-top: 8px;

      li {
        position: relative;
        padding-left: 5px;
        margin-bottom: 5px;

        &::before {
          content: "\2022";
          position: absolute;
          left: -10px;
          top: 9px;
          color: #808080;
          font-size: 7px;
          line-height: 1;
        }
      }
      &__title{
        font-family: 'Jost';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }
  }
  &__cards-row{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
  }
  &__scroll-view{
    overflow-y: scroll;
    height: 55vh;

    &::-webkit-scrollbar{
      display: none;
    }
  }
  &__menu_deleteTitle{
    font-weight: 400;
    font-size: 16px;
    font-family: Jost;
    line-height: 20px;
    color: #2B323B
  }
  &__no-checklist-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    background-color: #ffffff;
    height: 55vh;
  }
  &__no-checklist-p{
    font-weight: 400;
    font-size: 16px;
    font-family: Jost;
    line-height: 20px;
    color: #2B323B;
  }
  &__dashboard-option-menu {
    div {
      margin-bottom: 4px;
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 6px;
      margin-right: 24px;
      cursor: pointer;
      img {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
    }
  }
  &__dashboard-menu-item {
    &:hover {
      background-color: #F7F7F7;
    }
  }
  &__dashboard-menu-item-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.driverNumberDesignError{
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: #FFEBEB;
  width: max-content;
  font-family: 'Jost';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #E92B36;
}
.driverNumberDesignSuccess{
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: #EEFDF2;
  width: max-content;
  font-family: 'Jost';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #56CD9D;
}
.textDesign{
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #404040;
}