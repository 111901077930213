$DOWNLOAD_BTN_BACKGROUND: #f8f9fa;
$DOWNLOAD_BTN_BORDER: #f0f0f0;
$DOWNLOAD_BTN_COLOR: #0000008c;
$LIST_CONTAINER_BACKGROUND: #f2f4f7;
$TITLE_COLOR: #2b323b;
$SUB_TITLE_COLOR: #878787;
$SCROLL_BAR_BACKGROUND: #f5f5f5;
$SCROLL_BAR_SHADOW: rgba(79, 75, 75, 0.104);
$GREEN_BACKGROUND: #d6f4de;
$GREEN_TEXT: #56cd9d;

.PDT_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 190px);
  height: calc(100% - 70px);
  margin-left: 190px;
  padding: 30px;

  .rt-tr.-even {
    background: #fff !important;
  }

  .pdt_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    .upload_container {
      display: flex;
      // justify-content: space-between;
    }

    .upload_button {
      position: relative;
      margin-right: 10px;
      
      .upload_button_filled {
        color: #fff;
        border-radius: 5px;
        background-color: orange;
        padding: 8px 12px;
        font-weight: 400;
      }
    }

    .hidden {
      display: none;
    }

    .title {
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
    }
    .download_button {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 400;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid #DEDEDE;
      margin-right: 20px;
    }
  }
  
}

.downloadMoal {
  &__header {
    margin-bottom: 300px;
  }
  &__progress_bar {
    margin-top: 32px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }
  } 
  &__downloading_title {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
    margin-bottom: 32px;
  }
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: #2B323B;
  }
  &__button_container {
    display: flex;
    margin-top: 64px;

    &__cancel_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
    &__download_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }
}
