@import "../../../../styles/constants.scss";

.routines-excel-download-container {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  .routines-excel-download-description {
    color: #404040;
    font-size: 16px;
    margin-right: 12px;
  }
  .routines-download-button {
    color: $success;
    padding: 7px 8px;
    font-size: 11;
    margin-right: 80px;
  }
}
