.factory-rankings-analytics {
    .table-details {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: #808080;
      margin-top: 15px;
    }
  
    .table-row {
      display: flex;
      color: #808080;
  
      p:nth-child(1) {
        flex: 1
      }
      p { flex: 1 }
  
      &:nth-child(2n+1) {
        background-color: #FAFAFA;
      }
    }
  
    .table {
      margin-top: 3px;
      height: 203px;
      // height: 300px;
      overflow: scroll;
      margin: 10px 0px;
      font-size: 12px;
    }
  
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 12px;
    }
  
    .header-title {
      display: flex;
      flex-direction: row;
    }
  
    .overview-header {
      margin-left: 8px;
    }
  
  }