.violence-prevention {
  display: flex;
  flex: 1;

  .cashless-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
  }

  .loaderPosition {
    display: block;
    margin: auto;
  }

  .align-self-center {
    align-self: center;
  }

}
