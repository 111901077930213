
.reportBoard-consequence {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
  padding: 25px;
  position: relative;

  &__top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-right: 6px;

    &__title {
      color: #878787;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.21px;
      line-height: 18px;
    }

    &__buttons {
      display: flex;

      .on-click-dropdown {
        margin-right: 14px;

        .full-content {
          div {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 16px;
            min-width: 140px;
          }

          .select {
            &:hover {
              background-color: #f0f0f0 !important;
            }
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

.reportBoard-consequence__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
}