.viewDailyAgenda {
  margin-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 16px;

    &__info {
      display: flex;
      align-items: center;

      &__goBack {
        padding: 12px 16px;
        border: 1px solid #D6D6D6;
        border-radius: 8px;
        background-color: #FFFFFF;
        margin-bottom: 8px;
        cursor: pointer;
      }
      &__content {
        margin-left: 16px;

        &__title {
          font-family: 'Outfit';
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #000;
        }
        &__buSelectorWrap {
          display: flex;
          align-items: center;
          padding-top: 8px;

          &__label {
            font-family: 'Jost';
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #404040;
            margin-right: 8px;
          }

          &__dropdown {

            &__label {
              padding-left: 18px !important;
              padding-right: 18px !important;
              padding-top: 4px !important;
            }

            &__dropdownLabel {
              font-family: 'Roboto';
              font-size: 13px;
              font-weight: 500;
              line-height: 16px;
              text-align: center;
              color: #4472C4;
            }

            &__icon {
              margin-top: -1px !important;
            }

            &__container {
              background-color: unset !important;
              border: 1px solid #DEDEDE;
              height: 27px !important;
            }
          }
        }
      }
    }
    &__edit_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #DEDEDE;
      margin-left: 8px;
    }
  }
  &__noDataWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vh;
    justify-content: center;
    margin-left: 80px;
    margin-right: 80px;

    &__title {
      font-family: 'Jost';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #43434399;
    }
    &__subtitle {
      font-family: 'Jost';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: #B5B5B5;
      margin-top: 8px;
    }
  }
  &__contentWrap {
    width: 100%;
      
    &__effectiveDateLabel {
      font-family: 'Jost';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #B5B5B5;
      margin-bottom: 16px;
    }

    &__activitiesWrap {
      display: flex;
      justify-content: space-between;
      width: 99%;
      
      &__content {
        margin-top: 16px;
        width: 49%;
    
        &__activityWrap {
          display: flex;
          margin-bottom: 16px;
  
          &__positionWrap {
            width: 50%;
  
            &__position {
              font-family: 'Jost';
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: #000000;
            }
          }
    
          &__activities {
            width: 70%;
    
            &__titleWrap {
              border-bottom: 1px solid #F0F0F0;
              background-color: #FFFFFF;
              padding: 12px 16px;
              display: flex;
    
              &__title {
                font-family: 'Jost';
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                color: #B5B5B5;
              }
            }
            &__wrap {
              background-color: #FFFFFF;
  
              &__itemWrap {
                display: flex;
                justify-content: space-between;
                padding: 12px 16px;
  
                &__list {
                  &__title {
                    font-family: 'Jost';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    color: #404040;
                    margin-top: 12px;
                  }

                  &__noMarginTop {
                    margin-top: 0px !important;
                  }
                }
  
                &__frequencyTitle {
                  font-family: 'Jost';
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  text-align: right;
                  color: #808080;
                }
              }
            }
          }
        }
      }
  
      &__updatedContent {
        margin-top: 16px;
        width: 49%;
        border: 1px solid #F7F7F7;
        border-radius: 8px;
        padding: 24px;
        background-color: #F0F0F0;

        &__heading {
          font-family: 'Jost';
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #B5B5B5;
          margin-bottom: 16px;
        }
  
        &__activityWrap {
          display: flex;
          margin-top: 16px;
  
          &__positionWrap {
            width: 50%;
  
            &__position {
              font-family: 'Jost';
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              color: #000000;
            }
          }
    
          &__activities {
            width: 70%;
    
            &__titleWrap {
              border-bottom: 1px solid #F0F0F0;
              background-color: #FFFFFF;
              padding: 12px 16px;
              display: flex;
    
              &__title {
                font-family: 'Jost';
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                color: #B5B5B5;
              }
            }
            &__wrap {
              background-color: #FFFFFF;
  
              &__itemWrap {
                display: flex;
                justify-content: space-between;
                padding: 12px 16px;
  
                &__list {
                  &__title {
                    font-family: 'Jost';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    color: #56CD9D;
                    margin-top: 12px;
                  }

                  &__noMarginTop {
                    margin-top: 0px !important;
                  }

                  &__lineThrough {
                    text-decoration: line-through !important;
                    color: #D6D6D6 !important;
                  }
                }
  
                &__frequencyTitle {
                  font-family: 'Jost';
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  text-align: right;
                  color: #808080;
                }
              }
            }
          }
        }

        &__noMarginTop {
          margin-top: 0px !important;
        }
      }
    }
  }
  .crossedClass {
    text-decoration: line-through;
    color: #B5B5B5;
  }

  .greenClass {
    color: green;
  }
}

