.top-cds {
  display: flex;
  flex-direction: column;
  width: calc(100% + 32px);
  margin-left: -16px;

  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    padding-left: 25px;
  }
}
