$primary: #C00000;
.outline-button {
  background-color: white;
  outline: 0 !important;
  border: 2px solid $primary;
  border-radius: 25px;
  padding: 5px 16px;
  text-align: center;
  //-webkit-appearance: none;
  color: $primary;

  .pill-button-text {
    color: $primary;
    font-weight: bold;
  }
}
