@import "../../../../styles/constants";

.register-bg  {
  background: $full-color-background;
  height: 100vh;
  width: 100vw;
}

.register {
  background: $full-color-background;

  .main-card-container {
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 30px;
    border: 3px solid $PRIMARY;
    box-shadow: 0 8px 30px 0 rgba(177, 177, 177, 0.5);
  }

  .arvolution-logo {
    vertical-align: top;
    margin-top: 10px;
  }

  .header-text {
    font-weight: lighter;
    font-size: 50px;
    line-height: 52px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 30px;
    // position: absolute;
    // width: 262px;
    // left: 50%;
    // transform: translate(-50%);

    // &:hover {
    //   box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
    // }
  }

  .input-label {
    margin-top: 5px;
  }

  .textError {
    color: red;
  }

  .main-action {
    margin-top: 60px;
    text-align: center;
  }

  .register-spinner {
    display: inline-block;
    margin-bottom: 26px;
    margin-left: 50px;
  }

  .detail-text {
    color: #0D326E;
    font-size: 13px;
    margin-top: 10px;
  }

  .link {
    text-decoration: underline;
  }

  .invalid-token {
    margin-top: 184px;
    margin-bottom: 185px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .go-to-home {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    background-color: $PRIMARY;
    margin-top: 15px;
    color: white;
    text-decoration: none;
  }

  .footer {
    margin: auto;
    padding-bottom: 20px;
  }

  .footer-text {
    color: $white;
  }
  
  .chechbox-container {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-right: 16px;

    .styleText {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: underline;
      cursor: pointer;

    }
  }
}
