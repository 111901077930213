.empty-state {
  width: 100%;
  height: 213px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  img {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
  }

  span {
    font-size: 18px;
    letter-spacing: 0.09px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    text-align: center;
    color: #878787;
    max-width: 265px;
  }
}