.p {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  margin: 8px 0;
  color: #2b323b;
}

.flex-center {
  display: flex;
  align-items: center;
}

.access-label {
  font-family: "Nunito Sans", sans-serif;
}

.bu-sections {
  font-weight: 500;
  font-size: 12px;
  color: #808080;
}

.MuiCollapse-wrapper {
  font-family: "Nunito Sans", sans-serif;
}