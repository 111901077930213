$grey-color: #DADADA;
$black-text: #030405;
$date-picker-month-in-range: #8ae9a3;
$date-picker-month-selected: #56CD9D;
$success: #56CD9D;

.gkpi-analytics{
  min-height: 100vh;
  .gkpi-header {
    height: 70px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: $black-text
    }
    .upload-button{
      height: 40px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .gkpi-filters-header-section{
    .date-range-selector{
      border: $grey-color solid 1px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 16px;
      width: 220px;
      height: 40px;
      padding: 0px 10px;
      calender-icon{
        height: 20px;
        width: 20px;
      }
      .location-selector{
        .dropdown-label{
          padding: 0
        }
        .country-selector{
          min-width: 200px;
        }
      }
      .react-datepicker{
        .react-datepicker__month-wrapper{
          .react-datepicker__month--in-range{
            background-color:$date-picker-month-in-range;
          }
          .react-datepicker__month-text--keyboard-selected{
            background-color: $date-picker-month-selected;
          }
        }
      }
      .react-datepicker-wrapper{
        input{
          cursor: pointer;
          width: 80px;
        }
        .react-datepicker__tab-loop{
          display: inline;
        }
      }
    }
    .dropdown-container{
      &:focus-within{
        box-shadow: $success 0 0 0 1px;
        border-color: $success;
      }
    }
    .multi-select{
      width: 300px;
    }
  }
  .gkpi-representation-table{
    margin-top: 32px;
    .loader{
      margin-top: 64px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .expandable-table{
      overflow-x: scroll;
      thead{
        th{
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          min-width: 80px;
          &:first-child{
            min-width: 300px;
          }
        }
      }
    }
  }
}



