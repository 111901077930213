@import "../../../styles/color-codes";
.verify-user-header {
  margin: 0 10px 40px 10px;
}
.verifyButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.detailsContainer {
  justify-self: flex-start;
  width: 100%;
  padding: 30px;
  margin-top: 30px;
  border-top: 2px solid $BORDERS;
  border-bottom: 2px solid $BORDERS;
}

.labelValueContainer {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.labelForPinDetails {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  min-width: 100px;
}

.valueForPinDetails {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
}
