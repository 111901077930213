.general-planning-analytics {
  .unit-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon-name-container {
      display: flex;
      flex-direction: row;
    }

    img {
      width: 10px;
    }
  }

  .spacer-line {
    border-top: 1px solid #F0F0F0;
    margin: 9px 0px;
  }

  .unit-section {
    margin: 70px 0px;
  }
}