.header-dropdown {
  margin-right: 8px;

  .select:hover {
    background: #F2F2F2 !important;
  }

  .option {
    &:hover {
      background: #F7F7F7 !important;
    }

    &.all {
      font-weight: bold;

      p {
        color: #70AD47;
      }
    }
  }


}

.header-dropdown-no-dropdown {
  margin: 5px;
  padding-left: 5px;
}
