$PRIMARY_DARK: #2b323b;
.sif-stats {
  .heading1 {
    font-weight: 700;
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
  }
  .drop_down_section {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-top: -54px;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiInput-underline {
      text-decoration: none !important;
    }
    .select {
      background: transparent;
      min-width: 100px;
    }
    .reset-button{
      padding-right: 40px;
    }
  }

  .label-default {
    min-width: 150px;
    span {
      min-width: 130px;
    }
  }

  .single_graph_title {
    color: $PRIMARY_DARK;
    font-size: 1.2rem;
  }
  .heading2 {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .sub_heading {
    font-size: 1rem;
    margin-right: 20px;
  }

  .graph_section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .incident_histogram_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 15px;
    border: 1px solid lightgray;
    background: white;
    margin-bottom: 30px;
  }
  .sub_heading_container {
    display: flex;
  }

  .table_container {
    height: 60vh;
    overflow: auto;
  }

  .stick-top{
    position: sticky;
    position: -webkit-sticky;
    top: -33px;
    background-color: #F7F7F7;
    z-index: 10;
    margin-top: -20px;
    margin-bottom: 20px;
    border: 0;
    border-bottom: 1px;
    border-color: grey;
    border-style: solid;
  }

  .label-info {
    color: #2574FB;
    margin: 20px 0;
  }

  .incident-detail-heading-text {
    display: flex;
    align-items: center;
  }

  .dropdown-label-month > span {
    color: #2574FB;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .rc-dropdown-menu  .rc-dropdown-menu-root .rc-dropdown-menu-vertical{
    min-width: 150px;
  }

  .sub-label {
    color: grey;
  }

  .sticky {
    position: sticky!important;
    top: 0;
    height: 0;
  }
}
