@mixin fade-up-animation {
  animation: fade-up 0.6s ease-out;
}

.form-details {
  @include fade-up-animation;
}

.dropdownLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.upload-file {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media only screen and (max-width: 600px) { 
  .dropdownLayout {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 1024px) { 
  .dropdownLayout {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-evenly;
  }
}