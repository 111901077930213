$BACKGROUND_RED: #a33223;
$BACKGROUND_BLUE: #5572b9;
$TEXT_GRAY: gray;

.font-weight500 {
  font-weight: 500;
}

.font-size24 {
  font-size: 24px;
}

.popup-header {
  justify-content: space-between;
  width: 100%;
}

.training-popup-bar {
  display: flex;
  background-color: $BACKGROUND_RED;
  width: 100%;
  height: 40px;
}

.training-popup-progress-bar {
  background-color: $BACKGROUND_BLUE;
  height: 40px;
  text-align: center;
  color: white;
  line-height: 32px;
}

.training-flex-end {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: $TEXT_GRAY;
}

.training-chart-label {
  align-self: flex-start;
  margin-bottom: -32px;
  color: $TEXT_GRAY;
  font-size: 12px;
  font-weight: 400;
}

.safety-training-selector {
  border: 2px solid rgb(223, 225, 230);
  border-radius: 5px;
}

.font-normal-normal,
.font-normal {
  font-weight: normal !important;
}

.safety-training-por-region-buscar-container {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 10px;
}

.safety-training-por-region-buscar {
  border-radius: 5px;
  border: 2px solid rgb(223, 225, 230);
  padding-left: 5px;
  display: inline-block;
  margin-bottom: 20px;
}
