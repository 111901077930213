@import "../../../styles/constants";

.input-with-label {
  input {
    width: 100%;
    border: 2px solid rgb(236, 239, 244);
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 5px 0;
  }
  .inputImg{
   position: absolute;
   top:14px;
   left:14px;
  }

  ::placeholder {
    color: #c5d1e0;
  }

  .error-text {
    text-align: right;
    color: $error-highlight;
  }

  .default-label {
    height: 24px;
  }

  .form-error {
    color: $error-highlight;
    border-color: $error-highlight;
  }

  .labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
