$fixed-footer-height: 9rem;

.anonymous-truck-complaint {
  position: relative;
  max-width: 100%; /*necessary for sticky footer*/
  min-height: 100%; /*necessary for sticky footer*/
  overflow-x: hidden; /*necessary for sticky footer*/
  overflow-y: scroll; /*necessary for sticky footer*/
  padding: 0 0 $fixed-footer-height 0; /*necessary for sticky footer*/
  margin: 0; /*necessary for sticky footer*/
  width: 100%;

  .step-page {
    min-height: 100%;
    width: 100%;
    max-width: 500px;
    padding: 0.5rem 1.5rem;
    margin: auto !important;
  }

  .justify-start {
    justify-content: flex-start !important;
  }

  .proceed-button-container {
    position: absolute;
    height: $fixed-footer-height;
    left: 0;
    right: 0;

    button {
      width: 80%;
      max-width: 450px;
    }
  }
}
