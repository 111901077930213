$background: #F4F4F6;
$ERROR: #E92B36;
$WHITE: #ffffff;
$SUCCESS: #56CD9D;
$SUCCESS_LIGHT: #D6F4DE;


.file-drop-zone {
  width: 368px;
  height: 240px;
  background: $background;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .file-upload-interactive-container{
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .upload-file-Icon {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .cancel-upload{
    color: $ERROR;
    text-decoration: underline;
    cursor: pointer;
  }
  .upload-button{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $WHITE;
    padding: 5px 10px;
    width: 100%;
    margin-top: 16px;
  }
  .file-uploading-status{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    .upload-percent-label-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .MuiLinearProgress-barColorPrimary{
      background-color: $SUCCESS;
    }
    .MuiLinearProgress-colorPrimary{
      background-color: $SUCCESS_LIGHT;
    }
    .MuiLinearProgress-root{
      height: 30px;
      border-radius: 5px;
    }
  }
  .error-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .error-heading{
      font-weight: 700;
      padding-bottom: 8px;
    }
    .retry-upload{
      text-decoration: underline;
      margin-top: 16px;
      cursor: pointer;
    }
  }
}
