@import "../../../styles/mixins";

.sif-route {
  display: flex;
  flex-direction: column;

  &__title {
    @include route-title();
    margin-bottom: 32px;
  }

  &__subtitle {
    @include route-subtitle();
    margin-bottom: 24px;
  }
  &__search-box {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 10px;
    width: 250px;
    line-height: 30px;
    margin-bottom: 30px;
    background-color: #f7f7f7;
  }
}
