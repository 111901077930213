.MonthBar {
  flex: 1;
  margin-top: -40px;

  .container-bar {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
  }

  .row-item {
    display: flex;
    font-size: 12px;
    flex:1;
    // justify-content: center;
  }

  .flex-item {
    display: flex;
    // justify-content: center;
  }

  .green  {
    color: green
  }

  .red {
    color: red
  }

}
