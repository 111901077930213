@import "../../../../styles/constants.scss";

.icp-main-container {
  background-color: white;
  width: 60vw;
  position: fixed;
  left: 20vw;
  top: 20vh;
  border-radius: 12px;
  padding: 20px 35px;
  // overflow: auto;
}

.icp-input {
  border-radius: 4px;
  border: 1px solid var(--fontcolorsgray-4, #DEDEDE);
  background: #FFF;
  box-shadow: 0px 2px 1px 0px rgba(166, 166, 166, 0.25);
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;

  color: var(--TEXT, #2B323B);

  /* Body/BODY-S */
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 133.333% */
}

.icp-upload-container {
  text-align: center;
  margin-top: 25px;
}

.icp-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px 0;
}

.icp-close-later, .icp-cancel-button, .icp-postpone-button {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--fontcolorsgray-4, #DEDEDE);

  color: var(--fontcolorsgray-10, #404040);
  text-align: center;

  /* Button/Pill BUTTON text */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.isgr-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 30%;
}

.isgr-buttons {
  flex-grow: 1;
  /* Button/Pill BUTTON text */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0px 5px 0px 5px;
  text-align: left;
}
.isgr-no-button {
  background-color: $hover-grey;
  border-radius: 4px;
  color: var(--fontcolorsgray-10, #404040);
}
.isgr-yes-button {
  // background-color: #FFEDBD;
  border-radius: 4px;
  // color: #EFBE3F;
  background-color: $hover-grey;
  color: var(--fontcolorsgray-10, #404040);
}
.isgr-button-selected {
  background-color: #FFEDBD;
  border-radius: 4px;
  color: #EFBE3F;
}
.isgr-text-container {
  padding: 10px;
}
.isgr-title-text {
  font-style: normal;
  font-weight: 700;
}

.loading-spinner-close-sio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: auto!important;
}

.icp-close-now {
  background-color: #4472C4;
  color: #ffffff;
  text-align: center;

  /* Button/Pill BUTTON text */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}