.search-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.search-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 12px 12px 16px;
  height: 48px;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
}
.user-list-container {
  background-color: #fff;
  padding: 32px;
  text-align: left;
  overflow: auto;

  .ReactTable.-striped .rt-tr.-even {
    background-color: inherit !important;

    &:hover {
      background-color: inherit !important;
    }
    &:focus {
      background-color: inherit !important;
    }
  }

  .ReactTable {
    .rt-td {
      text-align: left !important;
    }

    .rt-thead.-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 56px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
    }
    .rt-tr-group {
      height: 64px;
      display: flex;
      align-items: center;
      flex-direction: row;
      background: #fff;

      &:hover {
        background-color: #fafafa !important;
      }
      &:focus {
        background-color: #ffe6bd !important;
      }
    }
  }
}

.user-table {
  height: 65vh;
  min-height: 20vh;

  .-pageSizeOptions {
    display: none;
  }

  // .-striped {
  //   height: 95%;
  // }

  .table-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div, button {
      padding: 10px 20px;
    }


  }

  .page {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    width: 50%;
    border: 2px solid #eceff4;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 0 10px 0 0 ;
  }
}

.user-filter {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  .filter {
    margin: 0 20px 0 0;
  }
  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .reset {
    align-self: flex-end;
    padding-bottom: 5px;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
    }

    img {
      margin-right: 8px;
    }
  }

  .chart-dropdown {
    border-radius: 6px;
    border: 1px solid #d6d6d6;
  }
}

.hidden {
  display: none;
}
.upload_button_container {
  position: absolute;
  top: -10px;
  right: 300px;
}
.add_user_container {
  position: absolute;
  top: -10px;
  right: 0px;
}
.download_hc_container {
  position: absolute;
  top: -10px;
  right: 150px;
}
.upload_button_filled {
  color: #fff;
  border-radius: 5px;
  background-color: #e92b36;
  padding: 8px 12px;
  font-weight: 400;
}
.add_user_button_filled, .download_hc_button_filled {
  position: absolute;
  width: 140px;
  right: 0px;
  color: #fff;
  border-radius: 5px;
  background-color: #e92b36;
  padding: 8px 12px;
  font-weight: 400;
}
.user-search-container {
  position: relative;
}
.hc {
  &__downloadMoal {
    &__progress_bar {
      margin-top: 32px;

      &__title {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #2B323B;
      }
    }

    &__downloading_title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }

    &__title {
      font-family: 'Outfit', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 8px;
      color: #2B323B;
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 32px;
      color: #2B323B;
    }

    &__select_container {
      margin-top: 56px;

      &__title {
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        color: #2B323B;
      }

      &__description {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: #2B323B;
      }

      &__radio_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__radio_round {
          width: 1.1em;
          height: 1.1em;
          cursor: pointer;

          &:checked {
            accent-color: #6C63FF;
          }
        }

        &__text_container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          &__radio_title {
            font-family: 'Jost', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            // margin-left: 8px;
            color: #2B323B;
          }

          &__radio_description {
            font-family: 'Jost', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #98A5B4;
          }
      }
      }
    }

    &__button_container {
      display: flex;
      margin-top: 64px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #DEDEDE;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }
    }
  }
}