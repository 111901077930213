.highlightbutton {
  color: #FFFFFF;
  padding: 10px;
  text-align: center;
  width: 200px;
  border-radius: 6px;
  background: linear-gradient(90deg, #F16E55 0%, #C00000 100%);
  box-shadow: 0 2px 5px 0 rgba(63, 63, 63, 0.5);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.7;
    outline: none
  }
}
