$DOWNLOAD_BTN_BACKGROUND: #f8f9fa;
$DOWNLOAD_BTN_BORDER: #f0f0f0;
$DOWNLOAD_BTN_COLOR: #0000008c;
$LIST_CONTAINER_BACKGROUND: #f2f4f7;
$TITLE_COLOR: #2b323b;
$SUB_TITLE_COLOR: #878787;
$SCROLL_BAR_BACKGROUND: #f5f5f5;
$SCROLL_BAR_SHADOW: rgba(79, 75, 75, 0.104);
$GREEN_BACKGROUND: #d6f4de;
$GREEN_TEXT: #56cd9d;

.trello_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 190px);
  height: calc(100% - 70px);
  margin-left: 190px;
  .header {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: flex-end;
    background: white;
    padding: 20px 30px 20px 10px;
    .hide-disable {
      opacity: 0;
      cursor: default;
      width: 0;
      margin: 0;
      height: 0;
      padding: 0 !important;
    }
    .title {
      font-size: 20px;
      line-height: 20px;
      color: $TITLE_COLOR;
      letter-spacing: 0.1px;
      margin: 0;
    }
    .subtitle {
      font-size: 12px;
      line-height: 1;
      color: $SUB_TITLE_COLOR;
      padding: 0;
      margin: 0;
      margin-top: 3px;
    }
    &__right {
      display: flex;
      justify-content: space-around;
      .chechbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;

        .styleText {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .month-selector {
        background: $DOWNLOAD_BTN_BACKGROUND;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $DOWNLOAD_BTN_BORDER;
        border-radius: 3px;
        color: $DOWNLOAD_BTN_COLOR;
        font-size: 14px;
        line-height: 1;
        // padding: 3px 8px;
        margin-right: 16px;
        .dropdown-content {
          min-width: 120px;
          .option {
            padding: 8px 6px;
          }
        }
      }
      .active {
        padding: 5px;
      }
      .inactive {
        padding: 10px;
        cursor: not-allowed;
      }
      .filter-added {
        border: 1px solid $GREEN_TEXT;
      }
      .download_btn {
        background: $DOWNLOAD_BTN_BACKGROUND;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $DOWNLOAD_BTN_BORDER;
        border-radius: 3px;
        color: $DOWNLOAD_BTN_COLOR;
        font-size: 14px;
        line-height: 1;
        padding: 8px;
        cursor: pointer;
        img {
          margin-left: 5px;
          height: 13px;
          width: 16px;
        }
      }
    }
  }
  .different_board_column {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 50px 10px;
    padding-bottom: 0;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    .loader_for_action_log {
      width: 100%;
      max-height: calc(100vh - 200px);
      min-height: calc(100vh - 200px);
      display: flex;
      margin-right: 100px;
      padding-bottom: 200px;
      align-items: center;
      justify-content: center;
    }
    .single_column {
      min-width: 290px;
      display: flex;
      flex-direction: column;
      transition: all 500ms;
      &__header {
        display: flex;
        justify-content: flex-start;
        &__image {
          width: 25px;
          height: 25px;
          margin-right: 8px;
          margin-left: 13px;
        }
        &__title {
          align-self: center;
          font-size: 23px;
          line-height: 23px;
          margin: 0;
          padding: 0;
          font-weight: 700;
          color: $TITLE_COLOR;
        }
      }
      &__list_container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
        padding: 18px;
        border-radius: 20px;
        background: $LIST_CONTAINER_BACKGROUND;
        .no_card_container {
          width: 250px;
          display: flex;
          padding: 16px;
          border-radius: 8px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 40px;
          margin-bottom: 60px;
          color: #878787;
          font-size: 16px;
          text-align: center;
          &__image {
            width: 60px;
            height: 60px;
            margin-bottom: 16px;
          }
        }
      }
      &__vertical_scroll {
        max-height: calc(100vh - 230px);
        min-height: calc(100vh - 230px);
        overflow: hidden;
        overflow-y: scroll;
        scroll-behavior: smooth;
        transition: all 500ms;
      }
      &__vertical_scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
        box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
        border-radius: 10px;
        background-color: $SCROLL_BAR_BACKGROUND;
      }

      &__vertical_scroll::-webkit-scrollbar {
        width: 9px;
        background-color: $SCROLL_BAR_BACKGROUND;
      }

      &__vertical_scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
        box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
        background-color: lightgray;
      }
    }
  }
  .different_board_column::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
    box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
    border-radius: 10px;
    background-color: $SCROLL_BAR_BACKGROUND;
  }

  .different_board_column::-webkit-scrollbar {
    width: 9px;
    background-color: $SCROLL_BAR_BACKGROUND;
  }

  .different_board_column::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
    box-shadow: inset 0 0 6px $SCROLL_BAR_SHADOW;
    background-color: lightgray;
  }
  .MuiSnackbar-root {
    margin-right: 100px;
  }
}

.dnd_alert {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 20rem;
}
.create_action_log_container {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background: $GREEN_BACKGROUND;
  border-radius: 8px;
  cursor: pointer;
  .create_action_log_text {
    color: $GREEN_TEXT;
    font-weight: bold;
    font-size: 14px;
  }
}
.create_action_log_image {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.add_image {
  margin-right: 50px;
  width: 20px;
  height: 20px;
}
