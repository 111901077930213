.cashless-table {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Roboto;

  p {
    cursor: pointer;
  }

  .rt .tr-group {
    background: red;
    cursor: pointer;
  }

  .margin-left-25 {
    margin-left: 25px;
  }
}
