@import "../../styles/color-codes";

.config-dots {
  position: relative;
  width: fit-content;
  padding: 5px;
}

.config-dots > svg {
  margin: 1px 0;

  ellipse {
    fill: $PRIMARY;
  }
}

.three-dot-dropdown {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 10px;
  min-width: 175px;

  border-radius: 0 0 2px 2px;
  background-color: $WHITE;
  box-shadow: 0 20px 35px 0 rgba(201,201,201,0.5);
}

.three-dot-dropdown > .item {
  padding: 5px;
  border-bottom: 1px solid $BORDERS;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
}

.three-dot-dropdown > .item > img {
  margin-right: 10px;
}

.three-dot-dropdown > .item:last-child {
  border-bottom: none;
}
