.capex-grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
}

.display-flex {
  display: flex;
}

.display-row {
  display: row;
}

.font-weight700 {
  font-weight: 700;
}

.font-weight400 {
  font-weight: 400;
}

.font-size14 {
  font-size: 14px;
}

.font-size16 {
  font-size: 16px;
}

.font-size18 {
  font-size: 18px;
}

.font-size20 {
  font-size: 20px;
}

.margin8 {
  margin: 8px;
}

.capex-selector {
  display: inline-block;
  border: 2px solid rgb(223, 225, 230);
  border-radius: 5px;

  input {
    margin-top: 8px;
  }
}

.capex-donut {
  padding-left: 40px;
}

.capex-table-container {
  justify-content: space-between;
}

.capex-donut-center {
  margin-right: 16px;
  margin-left: 36px;
}

.capex-donut-info {
  margin-left: 80px;
  margin-top: -160px;
}

.capex-donut-subinfo {
  text-align: left;
  width: 35%;
}

.subinfo-container {
  display: flex;
  justify-content: space-between;
}

.capex-subinfo-card {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding-left: 24px;
  margin-top: 100px;
}

.capex-radio-buttons-choice {
  display: row;
  float: right;
}

.search-box-container {
  border-radius: 5px;
  border: 2px solid rgb(223, 225, 230);
  padding-left: 5px;
  display: inline-block;
  margin-bottom: 20px;
}

.capex-por-mes-o-por-proyecto-drop-down {
  margin-left: 0;
  margin-bottom: 20px;
}

.capex-por-mes-o-por-proyecto-drop-down {
  margin-left: 0;
  margin-bottom: 20px;
}

.capex-por-mes-o-por-proyecto-drop-down {
  margin-left: 0;
  margin-bottom: 20px;
}
