.table-by-regional-manager {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .max-height {
    max-height: 402px;
  }

  &__bottom-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: stretch;
    position: relative;

    button {
      display: flex;
      align-items: center;
      border: unset;
      margin-left: 10px;
      margin-right: 35px;
      cursor: pointer;
      padding: 10px;
      background-color: #f7f7f7;
    }
  }

  svg {
    margin-left: 12px;
    height: 14px;
    width: 20px;
    position: absolute;
    top: 14px;
    right: 10px;
    g {
      fill: black;
    }
  }
}

.download_btn {
  min-width: 180px;
  display: block;
  justify-content: space-between;
  align-items: center;
  .icon {
    margin-top: -2px;
  }
}
.download_text {
  margin-right: 15px;
}
