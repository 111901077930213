@import "../../../../styles/constants";

.linkaccounts {
  background: $full-color-background;
  width: 100vw;
  height: 100vh;

  .main-card-container {
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 30px;
    border: 3px solid #C00000;
    box-shadow: 0 8px 30px 0 rgba(177, 177, 177, 0.5);
    justify-content: center;
  }

  .input-link-accounts{
    width: 100%;
    margin-bottom: 20px;
  }

  .header-text {
    font-weight: bold;
    font-size: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }

  .button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 70px;
  }

  .link-accounts-text {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .main-action {
    display: inline-block;
    font-weight: bold;
  }
  
  .footer {
    margin: auto;
    padding-bottom: 20px;
  }
  
  .footer-text {    
    color: $white;
  }
}
