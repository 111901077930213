.dms {
  .labelsWrap {
    display: flex;
    justify-content: center;
    color: "#002033";
    margin-top: -40px;
  
    &__label {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
