@import "../../../../../styles/color-codes.scss";

.createUser {
    .user-info-wrapper {
      display: flex;
      justify-content: flex-end;
      z-index: 9999;
      width: 100%;
      padding: 10px 10px 0px 10px;
      background-color: white;
    }
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    
        &__userNameAndIcon {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 24px;
          font-weight: bolder;
          margin-top: 15px;
        }
    
        &__userIcon > img {
          padding: 10px;
          border: 8px solid $BORDERS;
          border-radius: 50%;
          width: 75px;
          height: 75px;
          object-fit: contain;
        }
    }
    &__labelInputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -5px;
    }
    &__startWorkingDateContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -5px;
        margin-top: 5px;
    }
    &__iconAndLabel {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 220px;
      height: 24px;
      margin-right: 4px;
  }
      &__radioButtons {
        margin-top: 5px;
        margin-left: -8px;
      }
      &__divider {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0.5px solid #DEDEDE;
      }
      &__permissionsTitle {
        font-weight: bold;
        margin-bottom: 10px;
      }
      &__bottom_container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-top: 20px;
    
        &__buttons {
          display: flex;
          justify-content: flex-end;
          z-index: 9999;
          width: 100%;
          padding: 10px 10px 0px 10px;
          background-color: white;
    
          &__cancel {
            padding: 10px 22px;
            color: #404040;
            background-color: #fff;
            font-weight: 400;
            font-weight: 700;
            font-size: 14px;
            font-family: 'Quicksand', sans-serif;
            border: 2px solid #DEDEDE;
            margin-right: 20px;
          }
    
          &__add {
            background-color: '#4472C4';
            padding: 10px 22px;
          }
        }
      }
}
