.roadSafetyResources {
  &__title{
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
  &__truckArchive{
    margin-bottom: 48px; 

    &__title{
      font-family: 'Plus Jakarta Sans';
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      margin-bottom: 8px;
    }
    &__uploadContainer{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      width: max-content;

      &__uploadNewTruckFile-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        cursor: pointer;
      }
      &__lastUpdated-title{
        font-family: 'Jost';
        font-size: 15px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        text-align: left; 
        color: #878787;     
        margin-left: 16px;
        cursor: default;
      }
    }
    &__downloadContainer{
      width: max-content;

      &__downloadTemplate-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        cursor: pointer;
      }
    }
  }
  &__informationResources{    
    &__title{
      font-family: 'Plus Jakarta Sans';
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      margin-bottom: 8px;
    }
    &__delivererContainer{
      width: max-content;

      &__delivererTruckPdf-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
    &__riskFlowContainer{
      width: max-content;

      &__riskFlowPdf-title{
        font-family: 'Jost';
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4472C4;
        cursor: pointer;
      }
    }
  }
  &__uploadModal {
    &__progress_bar {
      margin-top: 32px;

      &__title {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #2B323B;
      }
    }

    &__downloading_title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }

    &__tileWrap{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      &__title {
        font-family: 'Outfit', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 8px;
        color: #2B323B;
      }
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 32px;
      color: #2B323B;
    }

    &__select_container {
      margin-top: 56px;

      &__title {
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        color: #2B323B;
      }

      &__description {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: #2B323B;
      }

      &__radio_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__radio_round {
          width: 1.1em;
          height: 1.1em;
          cursor: pointer;

          &:checked {
            accent-color: #6C63FF;
          }
        }

        &__radio_title {
          font-family: 'Jost', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          // margin-left: 8px;
          color: #2B323B;
        }
      }
    }

    &__button_container {
      display: flex;
      margin-top: 64px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
        border: 1px solid #DEDEDE;
      }
    }
    &__button_retry_container {
      display: flex;
      margin-top: 64px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
        border: 1px solid #DEDEDE;
      }
    }
  }
  &__selectDc{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 161px;

    &__info{
      margin-top: 48px;
      &__title{
        font-family: 'Plus Jakarta Sans';
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
      }
      &__subTitle{
        font-family: 'Jost';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}