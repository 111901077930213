.downloadMoal {
  &__header {
    margin-bottom: 100px;
  }
  &__description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    color: #2B323B;
  }
  &__progress_bar {

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }
  } 
  &__downloading_title {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__select_container {
    position: absolute;
    top: 280px;

    &__title {
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      color: #2B323B;
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }

    &__radio_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__radio_round {
        width: 1.1em;
        height: 1.1em;
        cursor: pointer;

        &:checked {
          accent-color: #6C63FF;
        }
      }

      &__radio_title {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        // margin-left: 8px;
        color: #2B323B;
      }
    }
  }
  &__button_container {
    display: flex;

    &__cancel_buttons {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #DEDEDE;

      &:hover {
        background-color: #F2F2F2;
      }
      &:active {
        background-color: #D6D6D6;
      }
    }
    &__download_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;

      &:hover {
        background-color: #C7DCFFE9;
      }
      &:active {
        background-color: #4472C4;
      }
    }
  }

  &__continue_button_container {
    display: flex;
    justify-content: flex-end;

    &__continue_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  
  &__yearFilter {
    position: absolute;
    display: flex;
    top: 180px;
    flex-direction: row;

    &__yearTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 16px;
    }

    &__monthTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 18px;
    }
  }
}