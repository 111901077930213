@import "../../styles/constants";

.file-upload-container {
  .hidden-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .input-file-label {
    font-size: 1.35rem;

    &:hover {
      color: #607EF0;
      cursor: pointer;
    }
  }

  .uploaded-file {
    border-radius: 4px;
    max-width: 100%;
    max-height: 20vh;
    object-fit: contain;
  }

  .imageError {
    color: $PRIMARY;
  }
}