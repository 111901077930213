@import "../../../../styles/constants";

.login {
  background: $full-color-background;
  width: 100vw;
  height: 100vh;

  .main-card-container {
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 30px;
    border: 3px solid #C00000;
    box-shadow: 0 8px 30px 0 rgba(177, 177, 177, 0.5);
  }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #333;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      color: #666;

      strong {
        color: #333;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .verification-form {
      gap: 15px;
      margin-top: 20px;

      h4 {
        text-align: left;
        font-size: 0.85rem;
        color: #333;
      }

      input {
        width: 100%;
        padding: 10px;
        font-size: 0.9rem;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:disabled {
          background-color: #f5f5f5;
          color: #999;
        }
      }

      .main-action {
        padding: 10px 20px;
        font-size: 0.9rem;
        color: #fff;
        background: $full-color-background;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: auto;
      }
    }

    footer {
      margin-top: 20px;
      font-size: 0.75rem;
      color: #999;
    }
}

  .go-back-to-login-text {
    color: #0D326E;
    font-size: 15px;
    margin-top: 15px;

    a {
      margin-left: 10px;
    }
  }
  .login-spinner {
    padding-bottom: 20px;
  }
