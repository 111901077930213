.capex-modal {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.capex-modal_content {
  background-color: white;
  position: absolute;
  top: 20%;
  left: 30%;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.capex-close {
  color: Black;
  float: right;
}

.capex-popup-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.capex-popup-bar {
  background-color: #a33223;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.capex-popup-progress-bar {
  background-color: #5572B9;
  height: 40px;
}
.capex-popup-progress-success {
  background-color: #7EAA56;
  height: 40px;
}

.ticket-modal-details {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px; 
}
.ticket-modal-header{
  display: flex;
    justify-content: space-between;
    padding: 16px 10px 16px 0px;

    .closed-color{
      color: #86A85F;
    }

    .delayed-color{
      color: #B12419;
      ;
    }

}

.capex-popup-details {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.capex-popup-second-color {
  color: #002033;
}
.cell-two-items {
  display: flex;
  justify-content: space-between;
}

.cell-chevron {
  padding-right: 24px;
  cursor: pointer;
}

.capex-popup-details-box {
  border: 2px solid slategray;
  padding: 20px;
}