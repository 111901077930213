.tile-with-image {
  display: flex;
  flex-direction: row;
  background: #FAFAFA;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
  }

  &__left {
    display: flex;
    align-items: center;

    img {
      height: 66px;
      width: 66px;
      object-fit: cover;
      margin-right: 8px;
      border-radius: 8px 0 0 8px;
    }
  }

  &__right {
    padding: 8px 0;
    p {
      font-style: normal;
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #878787;
      letter-spacing: 0.03em;
    }

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: #B5B5B5;
      height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

    }
  }
}