$ALTERNATE_COLOR: #f7f7f7;
.ReactTable.-striped .rt-tr.-even {
  background-color: $ALTERNATE_COLOR;
}

.statistic_table {
  margin-top: 20px;
}

.ReactTable .rt-td * {
  font-weight: 500 !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 900 !important;
}
