.checkboxes-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  .title {
    width: 100px;
    height: 30px;
    padding-left: .5em;
    display: flex;
    align-items: center;

    .title-string {
      height: 16px;
      font-size: 12px;
      font-weight: 900;
      line-height: 16px;
      margin-right: 6px;
      letter-spacing: .02rem;
    }
  }

  .checkboxes {
    width: fit-content;
    display: flex;

    .chechbox-container {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1em;

      .styleText {
        height: 15px;
        font-size: 11px;
        font-weight: 300;
        line-height: 15px;
        margin-left: 3px;
      }
    }
  }
}
