.postpone-sio-modal {
  background-color: white;
  width: 60vw;
  position: fixed;
  left: 20vw;
  top: 20vh;
  border-radius: 12px;
  padding: 20px 35px;
  overflow: auto;
  height: 60vh;
}

.postpone-sio-section-container {
  display: flex;
  justify-content: start;
}

.postpone-sio-title {
  color: var(--TEXT, #2b323b);
  align-self: start;
  text-wrap: nowrap;
  font: 700 24px/100% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
  padding-top: 32px;
}

.postpone-sio-description-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.postpone-sio-date-container {
  width: 272px;
  border: 1px solid #d6d6d6;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.postpone-sio-date-container-selected {
  background-color: #ecf3ff;
}

.postpone-sio-date-icon {
  width: 24px;
  height: 24px;
}

.postpone-sio-date-input {
  height: 40px;
  z-index: 100;
}

.postpone-sio-date-input-selected {
  color: #4472c4;
}

.postpone-sio-buttons-container {
  margin-top: 20px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.postponed-ok-button {
  background-color: #4472c4;
  color: #ffffff;
  text-align: center;

  /* Button/Pill BUTTON text */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.loading-spinner-postpone-sio {
  align-items: center;
  top: 0px;
  display: flex;
  padding: 10px 16px !important;
  height: fit-content !important;
}
