$background: #F4F4F6;
$ERROR: #E92B36;
$WHITE: #ffffff;
$SUCCESS: #56CD9D;
$SUCCESS_LIGHT: #D6F4DE;

.file-processing-container{
  width: 368px;
  height: 240px;
  background: $background;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .upload-file-Icon {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
  }
  .error-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .error-heading{
      font-weight: 700;
      padding-bottom: 8px;
    }
    .retry-upload{
      text-decoration: underline;
      margin-top: 16px;
      cursor: pointer;
      color: $SUCCESS;
    }
  }
  .success-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .visit-analytics{
      text-decoration: none;
      margin-top: 16px;
      cursor: pointer;
      color: $SUCCESS;
    }
    .succes-heading{
      font-weight: 700;
      padding-bottom: 8px;
    }
    .retry-upload{
      text-decoration: underline;
      margin-top: 16px;
      cursor: pointer;
      color: $SUCCESS;
    }
  }
}

.hide-overflow-x{
  overflow-x: hidden;
}