@import "../../styles/color-codes";

.clicked {
  background-color: $SUCCESS !important;
}

.btn-container {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  .track-page {
    margin-right: 1rem;
  }
  // .spacer {
  //   flex: 1;
  // }
}