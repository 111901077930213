@import "styles/constants";

$active-border: 2px solid $primary;
$disabled: #9DAECB;
$border-radius: 4px;
$border: 1px solid rgba(152, 174, 252, 0.46);
$input-font-size: 1.25rem;
$input-padding: 15px 15px 15px 25px;

@keyframes fade-up {
  from {
    opacity: 0.2;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin fade-up-animation {
  animation: fade-up 0.4s ease-out;
}

.incident-details {
  /* outlines and placeholders */
  * {
    outline: none;

    ::placeholder {
      color: $placeholder-color !important;
      font-weight: 300;
    }
  }

  .city {
    p {
      font-size: 1.1rem;
    }
  }

  .street {
    margin-top: 40px;
    padding-bottom: 20px;

    .street-container {
      padding: 5px;
      height: 54px;
    }

    .or-text {
      text-align: center;
      margin-top: 10px;
      font-weight: bold;
      font-size: 1.3em;
    }

    .calculate-location {
      width: 100%;
      margin-top: 10px;
    }
  }

  .description {
    margin-top: 30px;

    .description-container {
      padding: $input-padding;
      height: 90px;
      font-size: $input-font-size;
    }
  }

  .file {
    .image-upload {
      margin-top: 1.3em;
    }

    .image {
      max-height: 150px;
    }

    @media (min-width: 992px) {
      .image {
        max-height: 250px;
      }
    }
  }

  .issue-sub-type-dropdown-container {
    //padding-left: 8px;

    // fatten up label
    .anonymous-form-dropdown-label {
      font-weight: normal !important;
    }

    // override width
    .anonymous-form-dropdown {
      width: 100%;

      .full-content {
        width: 100%;
      }
    }

    // override check img
    img {
      margin-top: 4px;
      margin-right: 9px;
      width: 35px;
      height: auto;
    }
  }

  .risk-level-container {
    @include fade-up-animation;

    margin-top: 0.95rem;

    .toggle {
      width: 100%;

      .toggle-button {
        width: 49% !important;
        border-radius: $border-radius;
        border-width: 2px;

        &:not(:last-of-type) {
          margin-right: 2%;
        }
      }
    }
  }

  .proceed-button-container > button {
    &:disabled {
      background-color: $disabled !important;
    }
  }

  .error-message {
    color: red;
  }

  .dropdown-container {
    width: 100%;
  }
}
