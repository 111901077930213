.text-big {
  span {
    text-transform: capitalize;
  }
}

.routes_name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .logout_icon {
    width: 15px;
  }
}

.dateRange {
  display: flex;

  &__resetButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-left: 20px;
    margin-top: 30px;
  
    padding-left: 8.5px;
    padding-right: 8.5px;
  
    border: 1px solid #F0F0F0;
    border-radius: 3px;
    color: #2B323B;
  
    cursor: pointer;
    &:hover{
        background: #E4F2DA;
    } 
  
    p {
        line-height: 8px;
        margin-left: 6px;
    }
  }
  &__startDate {
    margin-right: 30px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
      margin-bottom: 5px;
    }
  }

  &__endDate {
    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
      margin-bottom: 5px;
    }
  }
}

.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div, button {
    padding: 10px 20px;
  }


}
