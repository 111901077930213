@import "../../../styles/constants.scss";

.distribution-centers-index {
  .icon {
    border: none;
    img {
      width: 0.95rem;
    }
  }
  .zn-dist {
    .icon img {
      width: 0.8rem;
    }

    .kpi {
      &:not(:first-child) {
        padding-left: 2rem;
      }
      &:not(:last-child) {
        padding-right: 2rem;
      }
    }
  }

  .row .col-auto:first-child {
    padding-left: 0;
  }

  .navi {
    button {
      background-color: transparent;
    }
  }
  .right-action-containers {
    display: flex;
  }
  .national-download {
    padding-left: 40px;
  }
}

.distribution-centers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 177px);
  height: calc(100% - 70px);
  margin-left: 177px;
  padding: 25px;
  position: relative;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 177px);
    height: calc(100% - 177px);
    margin-left: 177px;
  }

  &__top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-right: 6px;

    &__title {
      color: #878787;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.21px;
      line-height: 18px;
    }

    &__buttons {
      display: flex;

      .on-click-dropdown {
        margin-right: 14px;

        .full-content {
          div {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 16px;
            min-width: 140px;
          }

          .select {
            &:hover {
              background-color: #f0f0f0 !important;
            }
          }

          .dropdown-content {
            min-width: unset;
          }
        }
      }
    }
  }

  .downloadAllBtn{
    background-color:transparent;
    color: $default-grey;
    border: none;
    .iconDownloadBtn{
      margin-right: 5px;
    }
    &:hover{
        background-color: $hover-grey;
      }
  }

  .downloadAllIcon{
    width: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .download-excel{
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      p{
        margin-left: 5px;
      }
    }
  }
  .note{
    margin-left: 5px
  }
}
