$DOWNLOAD_BTN_BACKGROUND: #f8f9fa;
$DOWNLOAD_BTN_BORDER: #f0f0f0;
$DOWNLOAD_BTN_COLOR: #0000008c;
$LIST_CONTAINER_BACKGROUND: #f2f4f7;
$TITLE_COLOR: #2b323b;
$SUB_TITLE_COLOR: #878787;
$SCROLL_BAR_BACKGROUND: #f5f5f5;
$SCROLL_BAR_SHADOW: rgba(79, 75, 75, 0.104);
$GREEN_BACKGROUND: #d6f4de;
$GREEN_TEXT: #56cd9d;

.w-100 {
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 190px);
  height: calc(100% - 70px);
  margin-left: 190px;
  padding: 30px;

  background: #fff !important;

  .rt-tr.-even {
    background: #fff !important;
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .upload_container {
      display: flex;
      // justify-content: space-between;
    }

    .upload_button {
      position: relative;
      margin-right: 10px;

      .upload_button_filled {
        color: #fff;
        border-radius: 5px;
        background-color: orange;
        padding: 8px 12px;
        font-weight: 400;
      }
    }

    .tutorial_button {
      color: #fff;
      border-radius: 5px;
      background-color: #EFBE3F;
      padding: 8px 12px;
      font-weight: 400;
    }

    .hidden {
      display: none;
    }

    .title {
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
    }

    .download_button {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 400;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid #DEDEDE;
      margin-right: 20px;
    }
  }

}

.iac-filters-container {
  display: flex;
  margin-bottom: 20px;
}

.iac-filters-date-container {
  // height: 32px;
  border: 1px solid #D6D6D6;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 5px
}

.iac-filters-date-container-selected {
  background-color: #ECF3FF;
}

.iac-filters-date-icon {
  width: 24px;
  height: 24px;
}

.iac-filters-date-input {
  color: #404040;
  font-family: 'Quicksand';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: fit-content;
}

.iac-filters-date-selected {
  color: #4472C4;
}

.iac-reset-filter {
  cursor: pointer;
  border-radius: 4px;
  background-color: #ECF3FF;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 10px;

  color: #4472C4;
  font-family: 'Quicksand';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.tabs-container {
  margin-left: 0px;
  overflow: unset;
}

.section-container {
  display: flex;
  height: 85%;
  justify-content: space-between;
}

.section-left-container {
  padding: 10px 0;
  width: 48%;
}

.section-left-scroll-container {
  overflow: auto;
  height: 94%;
  margin-bottom: 10px;
}

.section-right-container {
  // background-color: #e0e0e0;
  padding: 10px 0px;
  width: 48%;
  overflow: auto;
}