.routine-followup {
  .header-row {
    display: flex;
    padding: 20px 20px 0px 15px;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-title {
    font-size: 17px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400
  }

  .upicon-size {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .tab-item {
    margin-right: 15px !important;
  }

  .label-default {
    margin-left: 15px;
    padding-bottom: 10px;
  }
  .header-percent-title {
    color: #70AD47;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.monthly-tabs {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px 15px;

  .tab-item {
    cursor: pointer;
    padding-top: 17px;
    display: flex;
    flex-direction: column;
    border-top: 2px solid;
    border-top-color: transparent;
    color: #878787;
  }

  .tab-item-selected {
    border-top: 3px solid;
    border-top-color: #70AD47;
    color: #404040;
  }

  .tab-title {
    font-size: 17px;
  }

  .tab-value {
    font-size: 20px;
    font-weight: bold;
  }

  .tab-item {
    margin: 0 25px;
  }

  .tab-item:first-child {
    margin-left: 0;
  }

  .tab-item:last-child {
    margin-right: 0;
  }
}

.header-right-content {
  font-size: 19px;
  // font-weight: 300;
  font-family: "Nunito Sans", sans-serif;
}

.center-content {
  padding: 20px 0px 34px 15px;
}
