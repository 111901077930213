.supply-routine-mobile {
  .tab-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
  }
  .tab {
    padding: 10px;
    // padding-bottom: 20px;
    // padding-top: 30px;
    text-align: center;
    flex: 1
  }
  .selected-tab {
    border-bottom: 3px solid;
    border-bottom-color: rgb(192, 0, 0);
  }
}
