@import "../../../../../../styles/color-codes";

.select_all_container {
  position: relative;
  width: "100%";
  justify-content: flex-start !important;
  margin: 0 -20px 0 -20px !important;
  .select_all {
    position: sticky;
    top: 0;
    padding-bottom: 40px;
    margin-right: 40px;
    padding-left: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    & > button {
      border-width: 0;
      border-radius: 8px;
      padding: 10px 20px;
      margin: 10px 0 0 0;
      font-size: 14px;
      font-weight: 700;
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: #dedede;
        color: $PRIMARY_DARK;
      }
    }
    .filter_button {
      display: flex;
      text-transform: "capitalize";
      border-radius: 8px;
      padding: 10px 20px;
      margin: 10px 0 0 0;
      font-size: 14px;
      font-weight: 700;
    }
    .pillar-active {
      color: $WHITE;
      background: #efbe3f !important;
      width: 150px;
    }
    .pillar-inactive {
      color: $BLACK;
      border-color: $BUTTON_TEXT;
      background: #fff4d9 !important;
      width: 150px;
    }
    .single-filter-button-active {
      color: $BLACK !important;
      background: #fff4d9 !important;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .filter-button-active {
      color: $BLACK !important;
      border-color: transparent;
      background: #fff4d9 !important;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .filter-button-inactive {
      color: #858585 !important;
      border-color: $BUTTON_TEXT;
      background: #fff4d9 !important;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  .routineList {
    width: 297px;
    padding-left: 20px;
  }
}

.assignSios{
  &__divider{
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #DEDEDE;
  }

  &__title {
    margin-bottom: 16px;
  }
}

.owdsDivider{
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid #DEDEDE;
}
