.downloadMoal {
  &__header {
    margin-bottom: 300px;
  }
  &__progress_bar {
    margin-top: 32px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }
  } 
  &__downloading_title {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
  }
  &__description {
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2B323B;
    margin-bottom: 32px;
  }
  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    color: #2B323B;
  }
  &__button_container {
    display: flex;
    margin-top: 64px;

    &__cancel_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
    &__download_button {
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #4472C4;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }

  &__continue_button_container {
    display: flex;
    margin-top: 64px;
    justify-content: flex-end;

    &__continue_button {
      color: #404040;
      border-radius: 4px;
      background-color: #DEDEDE;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 10px;
    }
  }

  &__yearFilter {
    position: absolute;
    display: flex;
    top: 180px;
    flex-direction: row;

    &__yearTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 16px;
    }

    &__monthTitle {
      font-family: 'Jost', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      padding-left: 18px;
    }
  }
}
.checklist_section {
  min-height: 600px;

  
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      margin-right: 30px;
    }

    .search-bar {
      width: 600px;
      max-width: 600px;
      margin-left: auto;
    }
  }

  &__body {

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  
    .hidden{
      display: none
    }
  

    .checklist_section_instructions {
      display: flex;
      justify-content: space-between;

      .checklist_section_button_filled {
        color: #fff;
        border-radius: 5px;
        background-color: #e92b36;
        margin-left: 15px;
        font-weight: 400;
      }
    }

    .selected-tab {
      border-bottom: 2px solid;
      border-bottom-color: #CAA038;
      color: #CAA038;
    }

    .tabs-container {
      margin-left: 0px;
    }

    .chart_header {
      display: flex;
      justify-content: space-between;
      position: relative;

      .chart_filters {
        display: 'flex';
        flex-direction: 'column';
      }

      .drop-downs-rowed{
        display: flex;
        justify-content: end;
        margin-top: 10px;
      }

      .react-datepicker {
        position: absolute;
        right: 0px;
      }
    }
    .chart_body{
      display: flex;
      align-items: center;
      min-height: 500px;
    }

    .table {
      margin-top: 30px;
      min-height: 300px;
      .rt-resizable-header-content{
          font-weight: 400;
          font-size: 16px;
      }

      .ReactTable {
        .rt-td {
          font-weight: 400 !important;
          font-size: 16px;
          text-align: left;
          padding-left: 10px
        }
      }
    }

    .chart {
      min-height: 300px;
      text-align: center;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ol {
      margin-top: 30px;
    }

    .checklist_section_button {
      border: 1px solid #dedede;
      border-radius: 5px;
      color: #808080;
      background-color: #fff;
      font-weight: 400;
    }

    .checklist_section_button_filled {
      color: #fff;
      border-radius: 5px;
      background-color: #f7b64c;
      margin-left: 10px;
      font-weight: 400;
    }
    .checklist_section_download_button_filled {
      color: #404040;
      border-radius: 4px;
      background-color: #fff;
      font-weight: 400;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid #DEDEDE;
    }
    .checklist_section_update_template_button_filled {
      color: #fff;
      border-radius: 5px;
      background-color: #e92b36;
      margin-left: 10px;
      font-weight: 400;
    }
  }
}
