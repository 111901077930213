$img-size: 50px;
.fetch-status-component {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fetch-img {
    height: $img-size;
    width: $img-size;
    margin-top: 5px;
  }
}
