@import "../../../styles/constants.scss";

.post-upload {
  display: flex;
  height: calc(100vh - 60px);
  background-color: white;
  min-height: 99vh;

  &__side-menu {
    height: calc(100vh - 60px);
    width: 258px;
    background-color: #fafafa;
    padding: 48px 16px;

    @media (max-width: 1435px) {
      width: 180px;
    }

    button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 12px;
      background-color: white;
      border: 1px solid #f0f0f0;

      img {
        margin-right: 8px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    padding: 48px 72px 49px 96px;
    width: 100%;
    overflow-y: scroll;

    &__go-back {
      display: flex;
      align-items: center;
      padding: 8px;
      padding-left: unset;
      margin-bottom: 40px;
      cursor: pointer;

      img {
        margin-right: 8px;
      }
    }

    &__first-step {
      display: flex;
      flex-direction: column;

      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #2b323b;
      }

      p {
        margin-bottom: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2b323b;
      }

      &__material-type {
        display: flex;
        padding-bottom: 40px;
      }
    }

    &__second-step {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      border-top: 1px solid #dedede;

      &__left {
        display: flex;
        flex-direction: column;
        width: 607px;
        margin-right: 30px;

        input {
          padding: 8px 0;
          margin-bottom: 4px;

          &:focus {
            border-bottom: 1px solid #f0f0f0;
          }
        }

        &__title {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          color: #878787;
        }

        &__subtitle {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #878787;
        }

        &__banner-dropzone {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 342px;
          margin-top: 26px;
          background: #fafafa;

          div {
            display: flex;
            align-items: center;
            padding: 16px;
            border: 1px dashed #b5b5b5;
            background: transparent;
            border-radius: 4px;
            position: relative;
            cursor: pointer;

            img {
              margin-right: 8px;
            }

            input {
              position: absolute;
              top: 0%;
              left: 0%;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }

        &__author-date {
          display: flex;
          width: 100%;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #2b323b;
          padding-top: 26px;
          margin-bottom: 60px;
          height: 24px;

          input {
            padding: unset;
            height: 16px;
          }

          button {
            background: unset;
            border: unset;
            color: $SUCCESS;
            margin-left: 8px;
          }

          .react-datepicker {
            z-index: 100;
            font-family: Roboto;
            border-color: $SUCCESS;

            &__header {
              background: $SUCCESS_LIGHT;
              border-bottom: 1px solid $SUCCESS;
            }

            &__day--selected {
              background-color: $SUCCESS;
            }
          }
        }

        .wrapperClassName {
          margin-bottom: 42px;
          border: 1px solid #f0f0f0;
          border-radius: 4px;
        }

        .rdw-editor-toolbar {
          display: flex;
          justify-content: space-between;
        }

        .editorClassName {
          height: 240px;
          padding: 15px;
          box-sizing: border-box;
        }

        .rdw-option-wrapper {
          background: white;
          border: unset;
          &:hover {
            box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
          }
        }

        .rdw-inline-dropdown {
          background: transparent;
          border: unset;
          &:hover {
            box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
          }
        }

        .rdw-dropdown-optionwrapper,
        .rdw-dropdown-wrapper {
          border: unset;
          &:hover {
            box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
          }
        }

        .rdw-dropdown-optionwrapper {
          border: 1px solid #f0f0f0;
        }

        &__attached-files {
          display: flex;
          flex-direction: column;
          margin-bottom: 42px;

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #808080;
            margin-bottom: 16px;
          }

          &__list {
            display: flex;
            flex-wrap: wrap;
          }

          &__upload-files {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fafafa;
            border: unset;
            width: 140px;
            height: 104px;
            position: relative;
            margin: 0 10px 10px 0;

            img {
              min-width: 20px;
              max-width: 100%;
              max-height: 100%;
            }

            &__remove-btn {
              position: absolute;
              background: white;
              border-radius: 50%;
              border: unset;
              top: 10px;
              right: 10px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;

              > img {
                width: 20px;
              }
            }

            input {
              position: absolute;
              top: 0%;
              left: 0%;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }

            p {
              width: 108px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              color: #2b323b;
            }
          }
        }

        &__submit-button {
          border: 1px solid #dedede;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 8px 12px;
          width: 182px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #404040;
          background: white;

          &:hover {
            background: #fafafa;
          }

          &:disabled {
            g {
              fill: #c2c2c2;
            }
          }

          svg {
            height: 24px;
            margin-right: 8px;

            g {
              fill: black;
            }
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        width: 312px;
        height: 440px;
        position: sticky;
        top: -5px;

        &__mandatory-fields {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #878787;
          margin-bottom: 16px;
        }

        div {
          margin-bottom: 16px;
        }

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #2b323b;
        }

        ul {
          margin-top: 16px;
          padding: unset;
        }
      }
    }
  }
}

.__mandatory-fields-post-upload-open.__mandatory-fields-post-upload-open {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #878787;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: none;
}

.post__upload-break-word {

}

.post__upload-fit-image {
  max-width: '-webkit-fill-available'
}