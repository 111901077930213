.basic-button-with-tooltip {
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 1em;
  outline: none;
  padding: 13px 23px;
  background-color: #2574FB;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
}

.download_button {
  color: #404040;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 400;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  border: 1px solid #DEDEDE;
  margin-right: 20px;
  
  &:hover {
    background-color: #F2F2F2;
  }
  &:active {
    background-color: #D6D6D6;
  }
}