@import "styles/constants";

.input-with-label-and-icon {
  .label {
    text-indent: 2px;
  }

  // img and input
  .input-with-label-and-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-top: 6px;

    input, textarea {
      flex: auto;
      height: 100%;
      width: 100%;
      outline: none !important;
      border: 0;
      resize: none;
      background-color: transparent;

      &::placeholder {
        color: $placeholder-color;
      }
    }

    img {
      margin-left: 6px;
      height: 40px;
    }
  }
}
