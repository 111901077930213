.iframe {
  width: 100%;
  height: 100vh;
  zoom: 0.85;
}
.iframeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}