.toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  :focus {
    outline: none;
  }

  .toggle-button {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0 2px 2px 0;

    /* one item */
    &:first-child:nth-last-child(1) {
      /* -or- li:only-child { */
      width: 100%;
    }

    /* two items */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .toggle-button {
      width: 50%;
    }

    /* three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ .toggle-button {
      width: 33.3333%;
    }

    /* four items */
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ .toggle-button {
      width: 25%;
    }
  }

  .button-on {
    color: white;
    background: linear-gradient(180deg, #F16E55, #C00000);
    border-width: 0;
  }

  .button-off {
    background-color: #F1F5FA;;
    color: #C4CBCC;
    border: 1px solid #DAE2EB;
  }
}
