.skap {
  &__chart_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    &__title {
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
      margin-bottom: 20px;
    }

    &__filters {
      margin-right: 20px;

      &__upload_button {
        color: #fff;
        border-radius: 4px;
        background-color: #f7b64c;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 700;
        font-family: 'Quicksand', sans-serif;
      }

      &__download_button {
        color: #404040;
        border-radius: 4px;
        background-color: #fff;
        font-weight: 400;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        font-family: 'Quicksand', sans-serif;
        border: 1px solid #DEDEDE;
      }
    }
  }

  &__tabs-container {
    margin-left: 0px;
    width: 100%;
  }

  &__selected-tab {
    border-bottom: 2px solid;
    border-bottom-color: #CAA038;
    color: #CAA038;
  }

  &__bottom-button-container {
    display: flex;
    justify-self: flex-start;
    margin-left: 20px;
    margin-bottom: 50px;
  }

  &__downloadMoal {
    &__progress_bar {
      margin-top: 32px;

      &__title {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #2B323B;
      }
    }

    &__downloading_title {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #2B323B;
    }

    &__title {
      font-family: 'Outfit', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 8px;
      color: #2B323B;
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 32px;
      color: #2B323B;
    }

    &__select_container {
      margin-top: 56px;

      &__title {
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        color: #2B323B;
      }

      &__description {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: #2B323B;
      }

      &__radio_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__radio_round {
          width: 1.1em;
          height: 1.1em;
          cursor: pointer;

          &:checked {
            accent-color: #6C63FF;
          }
        }

        &__radio_title {
          font-family: 'Jost', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          // margin-left: 8px;
          color: #2B323B;
        }
      }
    }

    &__button_container {
      display: flex;
      margin-top: 64px;

      &__download_button {
        color: #fff;
        border-radius: 4px;
        background-color: #4472C4;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }

      &__cancel_button {
        color: #404040;
        border-radius: 4px;
        background-color: #DEDEDE;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 10px;
      }
    }
  }
}