$DOWNLOAD_BTN_BACKGROUND: #f8f9fa;
$DOWNLOAD_BTN_BORDER: #f0f0f0;
$DOWNLOAD_BTN_COLOR: #0000008c;
$LIST_CONTAINER_BACKGROUND: #f2f4f7;
$TITLE_COLOR: #2b323b;
$SUB_TITLE_COLOR: #878787;
$SCROLL_BAR_BACKGROUND: #f5f5f5;
$SCROLL_BAR_SHADOW: rgba(79, 75, 75, 0.104);
$GREEN_BACKGROUND: #d6f4de;
$GREEN_TEXT: #56cd9d;

.toast {
  background-color: white;
  color: black;
}

.OWD_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 190px);
  height: calc(100% - 70px);
  margin-left: 190px;
  padding: 30px;

  &__note {
    font-size: 16px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .table-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div, button {
      padding: 10px 20px;
    }

    .page {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      width: 50%;
      border: 2px solid #eceff4;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 10px;
      font-size: 1em;
      outline: none;
      margin: 0 10px 0 0 ;
    }

    &__previousTitle {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
      font-weight: 400;
    }

    &__nextTitle {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
      font-weight: 400;
    }

    &__pageNumber {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
      font-weight: 400;
    }
  }

  .rt-tr.-even {
    background: #fff !important;
  }

  .owd_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    .upload_container {
      display: flex;
      // justify-content: space-between;
    }

    .upload_button {
      position: relative;
      margin-right: 10px;
      
      .upload_button_filled {
        color: #fff;
        border-radius: 5px;
        background-color: orange;
        padding: 8px 12px;
        font-weight: 400;
      }
    }

    .hidden {
      display: none;
    }

    .title {
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
    }

    .tutorial {
      padding: 8px;
      background-color: #f0f0f0;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 5px;
      }

      &__title {
        font-size: 12px;
        line-height: 16px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
      }
    }
  }

  .body {

    min-height: 60vh;

    .archive-content {
      display: flex;
    }

    .headerTitle {
      font-weight: 700;
      font-size: 17px;
      font-family: 'Jost', sans-serif;
      line-height: 24px;
    }
    
    .loading-spinner {
      margin-top: 200px !important;
    }

    .filters {
      margin-top: 24px;
      display: flex;

      .column{
        padding: 0px 10px;

        .react-datepicker-wrapper{
          border: 1px solid #D6D6D6;
          border-radius: 6px;
          background-color: #fff;
        }

      }

    }

    .template {
      color: #efbe3f;
      cursor: pointer;

      &__download {
        font-weight: 400;
        font-size: 16px;
        font-family: 'Jost', sans-serif;
      }
    }

    .owd_name {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
    }
    .owd_last_upload {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
    }
    
    .flex {
      display: flex;
    }
  
    .archive-content-container {
      display: flex;
      width: 90%;
    }

    .fileName-container {
      width: 95%;
    }

    .fileImage {
      height: 24px;
      width: 24px;
    }

    .fileName {
      font-size: 16px;
      font-family: 'Jost', sans-serif;
    }

    .upload-icon {
      display: flex;
      img {
        margin-right: 5px;
      }

      &__tilte {
        font-size: 16px;
        font-family: 'Jost', sans-serif;
      }
    }

    .archive {
      color: #4472c4;
      cursor: pointer;
      display: flex;
      
    }
    .loading {
      color: #4472c4;
    }
  }

  .empty-state {
    margin-top: 24px;
  }
  
  .no_location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .ReactTable .rt-tbody .rt-td {
    text-align: left !important;
  }
  .ReactTable .rt-td * {
    font-weight: 400 !important;
  }
  .ReactTable {
    max-height: 800px;
  }
  .action {
    display: flex;
    color: #4472c4;
    align-items: center;
    
    .dropdown-label {
  
      span {
        display: none;
      }
      img {
        margin: 0px !important;
      }
    }
    .chart-dropdown {
      height: auto !important;
      background: transparent;
      border: none;
      width: 10%;
  
      .dropdown-label{
        padding: 0px !important;
        color: #4472c4 !important;

        svg {
          color: #4472c4;
        }
      }
    }
    .uploadingFile {
      font-weight: 400;
      font-size: 15px;
    }
  }
}
.owd-search {
  margin-bottom: 16px !important;
}
