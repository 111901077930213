.file-upload {
  width: 800px;
  margin: auto;
  overflow: auto;

  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    padding: unset;
    margin-bottom: 32px;
  }

  &__buttons-row {
    display: flex;
    justify-content: space-between;
  }

  .tile-with-image {
    background-color: white;
    width: 386px;
    height: 104px;
    padding: 16px;
    border: 1px solid #DEDEDE;
    margin: unset;

    &__right {
      padding: 0;
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #404040;
    }

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      height: 48px;
      color: #808080;
    }
  }

  &__bottom {
    margin-top: 64px;
  }
}

.last-file-upload-date {
  color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
}

.last-file-upload-date-upload-required {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
