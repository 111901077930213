.big-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 240px;
  height: 348px;
  padding: 16px;
  border: 1px solid #DEDEDE;
  background-color: white;
  border-radius: 8px;
  font-family: Roboto;
  cursor: pointer;
  margin: 0 16px 16px 0;
  position: relative;

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
  }

  &__banner {
    width: calc(100% + 32px);
    height: 164px;
    margin: -16px 0 16px -16px;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
    margin-bottom: 8px;
  }

  &__content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #878787;
    max-width: 206px;
    margin-bottom: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__buttons-row {
    display: flex;
    position: absolute;
    bottom: 16px;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      width: 40px;
      background-color: white;
      cursor: pointer;
      margin-right: 8px;

      img {
        height: 24px;
        width: 24px;
      }

      &:hover {
        box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
      }
    }
  }
}

.downlaod-link {
  background-color: white;
  border: 1px solid #DEDEDE;
  color: #0000EE;
  padding: 8px 16px;
  border-radius: 4px;
}