$GRAY_100: #f2f2f2;
$GRAY_200: #808080;
$GRAY_300: #404040;
$GRAY_400: #f7f7f7;
$GRAY_500: #f0f0f0;
$GRAY_600: #2e2e2e;
$BTN_RED: #c00001;
$BORDER: lightgray;

.update_status_container {
  background: $GRAY_100;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  .update_status_sub_container {
    font-size: 16px;
    width: 600px;
    border: 1px solid $BORDER;
  }
}
.root_padding {
  padding: 1.4em 2em;
}
.update_page_container {
  background: transparent;
  color: $GRAY_200;
  .upper_section {
    padding: 1em 1.3em;
  }
  .text1 {
    font-size: 0.9em;
    font-weight: 400;
  }
  .last_text {
    font-size: 17px;
    margin-bottom: 70px;
  }
  .status_section {
    background: white;
    padding: 1em 1.3em;
    border-top: 1px solid $BORDER;
    border-bottom: 1px solid $BORDER;
    padding-bottom: 30px;
    .status_question {
      font-size: 1.1em;
      color: $GRAY_300;
    }
    .status_option_container {
      display: flex;
      justify-content: center;
      max-width: 100%;
      margin-bottom: 50px;
      .single_status_option {
        width: auto;
        padding: 12px 30px;
        display: flex;
        font-weight: bolder;
        align-items: center;
        background: $GRAY_400;
        border: 1px solid $GRAY_500;
        border-radius: 5px;
        cursor: pointer;
        transition: all 400ms;
        .status_option_image {
          width: 1.1em;
          height: 1.1em;
          margin-right: 8px;
        }
        .status_option_name {
          font-size: 0.9em;
          line-height: 0.9em;
        }
      }
      .active_option {
        background: $BTN_RED;
        color: white;
        .status_option_image {
          filter: sepia(10%);
        }
      }
    }
    .MuiSelect-root {
      min-width: 240px;
      font-size: 1.2em;
      color: $GRAY_200;
    }
    .menu_item {
      width: inherit;
      font-size: inherit;
      color: inherit;
    }
    @media (max-width: 500px) {
      .MuiInputBase-root {
        width: 90%;
      }
      .MuiSelect-root {
        min-width: 100%;
        font-size: 1.2em;
        color: $GRAY_200;
      }
    }

    .single_status_option:first-child {
      margin-right: 1.3em;
    }
  }
  .submit_section {
    border-top: 1px solid $BORDER;
    background: white;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .submit_btn {
    border: none;
    outline: none;
    width: 250px;
    padding: 15px 30px;
    background: $BTN_RED;
    color: white;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 900;
    letter-spacing: 0.4px;
    transition: all 400ms;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}
.conclude_container {
  color: $GRAY_200;
  font-size: 0.9em;
  font-weight: 400;

  .header_section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    &__header {
      color: $GRAY_300;
      font-size: 2em;
      line-height: 1;
    }
  }
  &_description {
    margin-top: 20px;
  }
}

.success_container {
  .success_icon_container {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .success_image {
      width: 2.3em;
      height: 2.3em;
    }
    .success_text {
      color: $GRAY_200;
      font-size: 0.9em;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  .success_description {
    background: white;
    display: flex;
    align-items: flex-start;
    .details_image {
      width: 3em;
      height: 3em;
      margin-right: 15px;
    }
    .details_text {
      display: flex;
      min-height: 80px;
      flex-direction: column;
      .details_title {
        font-weight: 700;
        color: $GRAY_600;
        font-size: 1em;
        line-height: 1.3;
      }
      .details_sub_title {
        color: $GRAY_300;
        font-size: 0.8em;
        font-weight: 400;
      }
      .details_footer_text {
        margin-top: 35px;
        color: $BTN_RED;
        font-size: 0.8em;
        cursor: pointer;
      }
    }
  }
  .ending_text {
    color: $GRAY_200;
    font-size: 0.9em;
    font-weight: 400;
  }
}

.btn_disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.center_loader {
  width: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}